import { useHistory } from 'react-router-dom'
import { Box, Divider, Grid } from '@mui/material'
import KpiCard from 'components/chord-ai/kpi/KpiCard'
import { Kpi } from 'components/chord-ai/types'
import { NavigationContext } from 'components/AuthenticatedRoutes'
import ChordAiMessage from 'components/chord-ai/chat-bot/ChatBotMessage'
import { useChordAi } from 'components/chord-ai/ChordAiContext'
import { useCallback, useEffect, useState } from 'react'
import { Loading } from 'components/common'

interface KpiCardProps {
  openChatBot: () => void
}

const Kpis: React.FC<KpiCardProps> = ({ openChatBot }) => {
  const history = useHistory()

  const handleAnalyzeClick = (kpi: Kpi) => {
    history.push(`/chord-ai/kpis/${kpi.slug}`)
  }

  const mockedMessage =
    "I've expanded your list of KPIs to include some others you might want to track. Let me know if you'd like to add or remove any of the KPIs in the list."

  const [isLoading, setIsLoading] = useState(true)
  const [kpis, setKpis] = useState<Kpi[]>([])

  const { chordAiClient } = useChordAi()

  const fetchKpis = useCallback(async () => {
    try {
      setIsLoading(true)
      const kpis = await chordAiClient?.getKpis()
      if (kpis) setKpis(kpis)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching KPIs:', error)
      throw error
    } finally {
      setIsLoading(false)
    }
  }, [chordAiClient])

  useEffect(() => {
    fetchKpis()
  }, [])

  return (
    <NavigationContext.Consumer>
      {({ navOpen }) => (
        <>
          <ChordAiMessage message={mockedMessage} onAskQuestion={openChatBot} />
          <Divider sx={{ mt: 2, mb: 2 }} />

          {isLoading && <Loading hideMask opacity={1} />}

          {!isLoading && !kpis.length && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '50vh',
              }}
            >
              No KPIs found
            </Box>
          )}

          {!isLoading && kpis.length > 0 && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {kpis.length > 0 && (
                <Grid container spacing={2}>
                  {kpis.map(mockedKpi => (
                    <Grid
                      item
                      key={mockedKpi.id}
                      xs={navOpen ? 14 : 12}
                      md={navOpen ? 12 : 6}
                      lg={navOpen ? 6 : 4}
                    >
                      <KpiCard kpi={mockedKpi} onAnalyzeClick={handleAnalyzeClick} />
                    </Grid>
                  ))}
                </Grid>
              )}
            </Box>
          )}
        </>
      )}
    </NavigationContext.Consumer>
  )
}

export default Kpis
