import { FormInput, FormSecureInput } from 'components/common'
import { H4 } from '@chordco/component-library'
import { FormField } from 'hooks'
import {
  Section,
  Row,
  CheckboxList,
  StyledCheckbox,
  FlexColumnContainer,
  StyledLabel as Label,
} from 'components/provisioning/styles/common'

type Props = {
  fields: Record<string, FormField>
  selectedModels: string[]
  setSelectedModels: (models: string[]) => void
}

enum LookerModels {
  SnowflakePerformance = 'snowflake_performance',
  SnowflakeAutonomy = 'snowflake_autonomy',
  SnowflakeSalesforce = 'snowflake_salesforce',
}

const LookerConfiguration: React.FC<Props> = ({ fields, selectedModels, setSelectedModels }) => {
  return (
    <Section>
      <H4>Looker Configuration (optional)</H4>
      <Row>
        <FormInput field={fields.lookerClientId} customWidth="100%" />
        <FormSecureInput field={fields.lookerClientSecret} customWidth="100%" />
      </Row>
      <FormInput field={fields.lookerGroupIds} customWidth="100%" />

      <FlexColumnContainer>
        <Label>Looker Folders</Label>
        <Row>
          <FormInput field={fields.lookerSharedFolderId} customWidth="100%" />
          <FormInput field={fields.lookerSharedAudiencesFolderId} customWidth="100%" />
          <FormInput field={fields.lookerQuickstartsFolderId} customWidth="100%" />
        </Row>
      </FlexColumnContainer>

      <>
        <Label>Models</Label>
        <CheckboxList>
          {Object.values(LookerModels).map((model, i) => (
            <StyledCheckbox
              key={i}
              field={{
                name: 'Model',
                label: model,
                type: 'boolean',
                value: selectedModels.includes(model),
                setValue: val => {
                  let updatedModels

                  if (val) {
                    updatedModels = [...selectedModels, model]
                  } else {
                    updatedModels = selectedModels.filter(c => c !== model)
                  }

                  // Keep track of the selected models in state for our UI purposes
                  setSelectedModels(updatedModels)

                  // Update the form field with the selected models so we can save them
                  // to the database as a comma-separated string
                  fields.lookerModels.setValue(updatedModels.join(','))
                },
              }}
            />
          ))}
        </CheckboxList>
      </>
    </Section>
  )
}

export default LookerConfiguration
