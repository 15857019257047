import { ReactNode } from 'react'
import { CredentialsConfig } from 'components/chord-cdp/configs'
import * as DestinationIcons from 'components/chord-cdp/icons'
import * as Configs from 'components/chord-cdp/configs/vendors'

export type DestinationType = {
  id: string
  title: string
  tags: string | string[]
  credentials?: CredentialsConfig
  icon?: ReactNode
  description: ReactNode
  comingSoon?: boolean
  hidden?: boolean
  deviceOptions?: DeviceOptions
  connectionOptions?: Record<string, string>
  usesBulker?: boolean
}

export type AnalyticsPluginDescriptor = {
  type: 'analytics-plugin'
  packageCdn: string
  moduleVarName: string
}

export type InternalPluginDescriptor = {
  type: 'internal-plugin'
  name: string
}

export type DeviceOptions = AnalyticsPluginDescriptor | InternalPluginDescriptor

export const DeviceDestinationsConnectionOptions = {
  events: '*',
  hosts: '*',
}

export const coreDestinations: DestinationType[] = [
  {
    id: 'clickhouse',
    icon: DestinationIcons.clickhouseIcon,
    title: 'Clickhouse',
    tags: 'Datawarehouse',
    description:
      'ClickHouse is an open-source column-oriented database management system specialized for online analytical processing of queries (OLAP).',
    credentials: Configs.clickhouseConnectionConfig,
    usesBulker: true,
    hidden: true,
  },
  {
    id: 'postgres',
    icon: DestinationIcons.postgresIcon,
    title: 'Postgres',
    tags: 'Datawarehouse',
    description: 'Postgres is a powerful, open source object-relational database system.',
    credentials: Configs.postgresCredentialsConfig,
    usesBulker: true,
    hidden: true,
  },
  {
    id: 'bigquery',
    icon: DestinationIcons.bigqueryIcon,
    title: 'BigQuery',
    tags: 'Datawarehouse',
    description: 'BigQuery is a cloud-based SQL data warehouse service developed by Google.',
    credentials: Configs.bigQueryCredentialsConfig,
    usesBulker: true,
    hidden: true,
  },
  {
    id: 'snowflake',
    title: 'Snowflake',
    tags: 'Datawarehouse',
    icon: DestinationIcons.snowflakeIcon,
    description: 'Snowflake is an independent a cloud data warehouse with compute-based pricing.',
    credentials: Configs.snowflakeConfig,
    usesBulker: true,
  },
  {
    id: 'redshift',
    icon: DestinationIcons.redshiftIcon,
    title: 'Redshift',
    tags: 'Datawarehouse',
    description:
      'Amazon Redshift is a cloud data warehouse that is optimized for the analytical workloads of business intelligence (BI) and data warehousing (DWH).',
    credentials: Configs.redshiftCredentialsConfig,
    usesBulker: true,
    comingSoon: true,
  },
  {
    id: 'mysql',
    icon: DestinationIcons.mysqlIcon,
    title: 'Mysql',
    tags: 'Datawarehouse',
    description: 'MySQL is a popular open source object-relational database system.',
    credentials: Configs.mysqlCredentialsConfig,
    usesBulker: true,
    hidden: true,
  },
  {
    id: 's3',
    icon: DestinationIcons.s3Icon,
    title: 'Amazon S3',
    tags: 'Block Storage',
    description: 'S3 is a cloud file storage service by Amazon',
    credentials: Configs.s3CredentialsConfig,
    usesBulker: true,
    hidden: true,
  },
  {
    id: 'gcs',
    icon: DestinationIcons.gcsIcon,
    title: 'Google Cloud Storage',
    tags: 'Block Storage',
    description: 'Google Cloud Storage is a cloud file storage service by Google',
    credentials: Configs.gcsCredentialsConfig,
    usesBulker: true,
    hidden: true,
  },
  {
    id: 'mixpanel',
    icon: DestinationIcons.mixpanelIcon,
    title: 'Mixpanel',
    tags: 'Product Analytics',
    description:
      'Mixpanel is a product analytics platform that provides insights into user behavior.',
    credentials: Configs.mixpanelCredentialsConfig,
    hidden: true,
  },
  {
    id: 'intercom',
    icon: DestinationIcons.intercomIcon,
    title: 'Intercom',
    tags: 'Product Analytics',
    description: 'Intercom is a customer messaging platform.',
    credentials: Configs.intercomCredentialsConfig,
    usesBulker: false,
    hidden: true,
  },
  {
    id: 'facebook-conversions',
    icon: DestinationIcons.facebookIcon,
    title: 'Facebook Conversions API',
    tags: 'Product Analytics',
    description: 'Facebook Conversion API is a tool for sending events to Facebook Ads Manager.',
    credentials: Configs.facebookConversionsCredentialsConfig,
  },
  {
    id: 'june',
    icon: DestinationIcons.juneIcon,
    title: 'June.so',
    tags: 'Product Analytics',
    description:
      'June.so is a product analytics platform that provides insights into user behavior.',
    credentials: Configs.juneSdkCredentialsConfig,
    hidden: true,
  },
  {
    id: 'braze',
    icon: DestinationIcons.blazeIcon,
    title: 'Braze',
    tags: 'Product Analytics',
    description:
      'Braze is a customer engagement platform used by businesses for multichannel marketing.',
    credentials: Configs.brazeCredentialsConfig,
  },
  {
    id: 'mongodb',
    icon: DestinationIcons.mongodbIcon,
    title: 'MongoDB',
    tags: 'Datawarehouse',
    description: 'MongoDB is a cross-platform NoSQL document-oriented database.',
    credentials: Configs.mongodbCredentialsConfig,
    hidden: true,
  },
  {
    id: 'ga4',
    icon: DestinationIcons.ga4Icon,
    title: 'Google Analytics 4',
    tags: 'Product Analytics',
    description:
      'Google Analytics 4 is a service offered by Google that reports website traffic data and marketing trends.',
    credentials: Configs.googleAnalyticsCredentialsConfig,
  },
  {
    id: 'posthog',
    icon: DestinationIcons.posthogIcon,
    title: 'Posthog',
    tags: 'Product Analytics',
    description: 'Posthog is an open-source product analytics tool.',
    credentials: Configs.posthogCredentialsConfig,
  },
  {
    id: 'amplitude',
    icon: DestinationIcons.amplitudeIcon,
    title: 'Amplitude',
    tags: 'Product Analytics',
    description: 'Amplitude is a product analytics platform',
    credentials: Configs.amplitudeCredentialsConfig,
    comingSoon: true,
  },
  {
    id: 'hubspot',
    icon: DestinationIcons.hubspotIcon,
    title: 'Hubspot',
    tags: 'CRM',
    description: 'Hubspot is a CRM.',
    credentials: Configs.hubSpotCredentialsConfig,
    hidden: true,
  },
  {
    id: 'devnull',
    icon: DestinationIcons.devnullIcon,
    title: '/dev/null',
    tags: 'Special',
    description:
      'This destination does not send any data anywhere. However, you can connect a function to this destination',
    credentials: {},
    hidden: true,
  },
  {
    id: 'segment-proxy',
    icon: DestinationIcons.segmentIcon,
    title: 'Segment',
    tags: 'Special',
    description: 'Forward events for to Segment-compatible endpoint.',
    credentials: Configs.segmentCredentialsConfig,
    hidden: true,
  },
  {
    id: 'webhook',
    icon: DestinationIcons.webhookIcon,
    title: 'Webhook',
    tags: 'Special',
    description:
      'Send data to any HTTP endpoint. You can use this destination to send data to Slack, Discord, or any other service that accepts HTTP requests. ',
    credentials: Configs.webhookCredentialsConfig,
    hidden: true,
  },
  {
    id: 'tag',
    icon: DestinationIcons.tagIcon,
    title: 'Tag',
    tags: 'Device Destinations',
    description:
      'Inserts any html or javascript into your page. Use this to add any third party tracking code as Google Analytics, Facebook Pixel, Twitter Pixel, etc.',
    credentials: Configs.tagCredentialsConfig,
    deviceOptions: {
      type: 'internal-plugin',
      name: 'tag',
    } as DeviceOptions,
    connectionOptions: DeviceDestinationsConnectionOptions,
  },
  {
    id: 'logrocket',
    icon: DestinationIcons.logRocketIcon,
    title: 'Log Rocket',
    tags: 'Device Destinations',
    description:
      'Log Rocket is a session replay tool that helps you understand how users interact with your app.',
    credentials: Configs.logRocketCredentialsConfig,
    deviceOptions: {
      type: 'internal-plugin',
      name: 'logrocket',
    } as DeviceOptions,
    connectionOptions: DeviceDestinationsConnectionOptions,
    hidden: true,
  },
  {
    id: 'ga4-tag',
    icon: DestinationIcons.ga4Icon,
    title: 'Google Analytics 4 (Device Mode)',
    tags: 'Device Destinations',
    description: 'Tracks users in Google Analytics with client side code snippet. ',
    credentials: Configs.gaDeviceDestinationCredentialsConfig,
    deviceOptions: {
      type: 'internal-plugin',
      name: 'ga4-tag',
    } as DeviceOptions,
    connectionOptions: DeviceDestinationsConnectionOptions,
  },
  {
    id: 'gtm',
    icon: DestinationIcons.gtmIcon,
    title: 'Google Tag Manager',
    tags: 'Device Destinations',
    description: 'Installs Google Tag Manager client code and sends events to Google Tag Manager.',
    credentials: Configs.gtmDeviceDestinationCredentialsConfig,
    deviceOptions: {
      type: 'internal-plugin',
      name: 'gtm',
    } as DeviceOptions,
    connectionOptions: DeviceDestinationsConnectionOptions,
    comingSoon: true,
  },
  {
    id: 'klaviyo',
    icon: DestinationIcons.klaviyoIcon,
    title: 'Klaviyo',
    tags: 'Email/SMS Marketing',
    description: 'Klaviyo is a leading email and SMS marketing platform.',
    credentials: Configs.klaviyoCredentialsConfig,
  },
  {
    id: 'attentive',
    icon: DestinationIcons.attentiveIcon,
    title: 'Attentive',
    tags: 'Email/SMS Marketing',
    description:
      'Attentive is a personalized mobile messaging platform for e-commerce and retail brands.',
    credentials: Configs.attentiveCredentialsConfig,
  },
  {
    id: 'pinterest-tag',
    icon: DestinationIcons.pinterestIcon,
    title: 'Pinterest Tag',
    tags: 'Device Destinations',
    description:
      'The Pinterest tag allows you to track actions people take on your website after viewing your Promoted Pin.',
    credentials: Configs.pinterestTagCredentialsConfig,
    deviceOptions: {
      type: 'internal-plugin',
      name: 'pinterest-tag',
    } as DeviceOptions,
    connectionOptions: DeviceDestinationsConnectionOptions,
  },
  {
    id: 'pinterest-conversions',
    icon: DestinationIcons.pinterestIcon,
    title: 'Pinterest Conversions',
    tags: 'Product Analytics',
    description:
      "Maximize your visibility into captured conversions and build a better understanding of your ad's effectiveness.",
    credentials: Configs.pinterestConversionsCredentialsConfig,
  },
  {
    id: 'facebook-pixel',
    icon: DestinationIcons.facebookIcon,
    title: 'Facebook Pixel',
    tags: 'Device Destinations',
    description: 'Tracks users in Facebook with client side code snippet.',
    credentials: Configs.facebookPixelCredentialsConfig,
    deviceOptions: {
      type: 'internal-plugin',
      name: 'facebook-pixel',
    } as DeviceOptions,
    connectionOptions: DeviceDestinationsConnectionOptions,
  },
]

export const coreDestinationsMap = coreDestinations.reduce((acc, destination) => {
  acc[destination.id] = destination
  return acc
}, {} as Record<string, DestinationType>)
