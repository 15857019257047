import {
  TenantConfigurationServiceRequest,
  TenantModelAmplificationRequest,
} from 'utils/notion/service'
import { hubClient } from '../http'
import {
  CreateTenantPayload,
  CreateTenantRequest,
  Tenant,
  TenantConfigSheet,
} from 'types'

export interface TenantsClient {
  getUserTenants: () => Promise<Tenant[]>
  createTenant: (data: CreateTenantRequest) => Promise<Tenant[]>
  createTenantConfigurationServiceRequest: (
    data: TenantConfigurationServiceRequest
  ) => Promise<any>
  createTenantModelAmplificationRequest: (
    data: TenantModelAmplificationRequest
  ) => Promise<TenantConfigSheet>
}

export const tenantsClient: TenantsClient = {
  getUserTenants: () => hubClient.get('tenants'),
  createTenant: data => {
    const payload: CreateTenantPayload = {
      tenant: {
        name: data.name,
        chordUuid: data.uuid,
      },
      store: {
        name: data.name,
      },
      environment: data.environment,
    }

    if (data.adminEmails) {
      payload.adminEmails = data.adminEmails.split(',') || []
    }

    if (data.censusApiKey) {
      payload.census = {
        apiKey: data.censusApiKey,
      }
    }

    if (data.fivetranGroupId) {
      payload.fivetran = {
        destinationGroupId: data.fivetranGroupId,
        filterConnectors: data.filterConnectors || '',
      }
    }

    if (
      data.lookerClientId &&
      data.lookerClientSecret &&
      data.lookerGroupIds &&
      data.lookerSharedFolderId &&
      data.lookerSharedAudiencesFolderId &&
      data.lookerQuickstartsFolderId &&
      data.lookerModels
    ) {
      payload.metadata = {
        lookerClientId: data.lookerClientId,
        lookerClientSecret: data.lookerClientSecret,
        lookerSharedFolderId: data.lookerSharedFolderId,
        lookerSharedAudiencesFolderId: data.lookerSharedAudiencesFolderId,
        lookerQuickstartsFolderId: data.lookerQuickstartsFolderId,
        lookerPermissions: {
          groupIds: data.lookerGroupIds?.split(',').map(Number) || [],
          models: data.lookerModels?.split(',') || [],
        },
      }
      payload.provisionMetrics = true

      if (data.omsPlan) {
        payload.metadata.data_plan_override = data.omsPlan
      }
    }

    if (data.plan === 'autonomy') {
      if (
        data.solidusApiHost &&
        data.solidusApiKey &&
        data.solidusTenantId &&
        data.solidusStoreId
      ) {
        payload.solidus = {
          apiKey: data.solidusApiKey,
          apiHost: data.solidusApiHost,
          solidusStoreId: data.solidusStoreId,
          solidusTenantId: data.solidusTenantId,
        }
      }
    }

    return hubClient.post(`provisioning/${data.plan}`, payload)
  },

  createTenantConfigurationServiceRequest: data => {
    const { tenantId, requestType, userName, userEmail } = data
    const url = `tenants/${tenantId}/notion/tenant_configuration_service_request`

    return hubClient.post(url, {
      request_type: requestType,
      user_name: userName,
      user_email: userEmail,
    })
  },

  createTenantModelAmplificationRequest: data => {
    const { tenantId, slug } = data
    const url = `tenants/${tenantId}/notion/model_amplification_request`

    return hubClient.post(url, {
      slug: slug,
    })
  },
}
