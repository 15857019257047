import { TextField } from '@mui/material'
import { CredentialsConfig } from 'components/chord-cdp/configs'
import GenericCredentialsForm from '../GenericCredentialsForm'

export const pinterestTagCredentialsConfig: CredentialsConfig = {
  tagId: {
    description:
      "If this setting is enabled, the Pinterest tag will be loaded with the existing traits of the logged user (if available). See the <a target='_blank' href='https://developers.pinterest.com/docs/overview/welcome/'>official documentation</a> for more information.",
    renderComponent: TextField,
    componentProps: {
      label: 'Pinterest Tag ID',
      type: 'text',
      required: true,
    },
  },
}

export const PinterestTagCredentialsForm = props => (
  <GenericCredentialsForm {...props} config={pinterestTagCredentialsConfig} />
)
