import { Switch, TextField } from '@mui/material'
import { CredentialsConfig } from 'components/chord-cdp/configs'
import GenericCredentialsForm from '../GenericCredentialsForm'

export const pinterestConversionsCredentialsConfig: CredentialsConfig = {
  appName: {
    description: 'The name of the Site from which the event originated.',
    renderComponent: TextField,
    componentProps: {
      label: 'App Name',
      type: 'text',
      required: true,
    },
  },
  adAccountId: {
    description:
      "Unique identifier of an ad account. This can be found in the Pinterest UI by following the steps mentioned <a href='https://developers.pinterest.com/docs/conversions/conversions/#Find%20your%20%2Cad_account_id'>here</a>.",
    renderComponent: TextField,
    componentProps: {
      label: 'Ad Account ID',
      type: 'text',
      required: true,
    },
  },
  conversionToken: {
    description:
      "The conversion token for your Pinterest account. This can be found in the Pinterest UI by following the steps mentioned <a href='https://developers.pinterest.com/docs/conversions/conversions/#Get%20the%20conversion%20token'>here</a>.",
    renderComponent: TextField,
    componentProps: {
      label: 'Conversion Token',
      type: 'password',
      required: true,
    },
  },
  testMode: {
    description: 'Enable test mode, which shows all events in a debug tool on the Pinterest UI',
    renderComponent: Switch,
    componentProps: {
      label: 'Test Mode',
      type: 'checkbox',
      defaultChecked: false,
    },
  },
}

export const PinterestConversionsCredentialsForm = props => (
  <GenericCredentialsForm {...props} config={pinterestConversionsCredentialsConfig} />
)
