import React from 'react'
import { AlertModal } from '@chordco/component-library'
import { Save } from '@mui/icons-material'
import { useDataSettingsData } from 'redux/state/dataSettings'
import { DataSettingsPayload } from 'types'
import { List, ListItem, ListItemText } from '@mui/material'

type Props = {
  onClose: () => void
  changes: string[]
  data: DataSettingsPayload
}

export const SaveConfirmationModal: React.FC<Props> = ({ onClose, changes, data }) => {
  const { updateDataSettings } = useDataSettingsData()

  const handleSaveChanges = () => {
    updateDataSettings && updateDataSettings(data)
    onClose()
  }

  return (
    <AlertModal
      onClose={onClose}
      icon={Save}
      content="Are you sure you want to save these changes?"
      centered
      width="400px"
      buttons={[
        {
          id: 1,
          text: 'Cancel',
          onClick: onClose,
          purpose: 'tertiary',
          disabled: false,
        },
        {
          id: 2,
          text: 'Save',
          onClick: handleSaveChanges,
          purpose: 'primary',
          isLoading: false,
          disabled: false,
        },
      ]}
    >
      <List dense>
        {changes.map((change, index) => (
          <ListItem key={index}>
            <ListItemText primary={change} />
          </ListItem>
        ))}
      </List>
    </AlertModal>
  )
}
