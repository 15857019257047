import { useState } from 'react'
import styled from 'styled-components'
import { useIsMobile } from 'hooks'
import useUserRole from 'redux/hooks/useUserRole'
import { Body, Button, H1, H2, Tooltip } from '@chordco/component-library'
import { DataSettings, DataSettingsPayload, DataSettingsLogEntry, Timezone } from 'types'
import { WeekDefinitionPanel } from './WeekDefinitionPanel'
import { FiscalYearAndPeriodPanel } from './FiscalYearAndPeriodPanel'
import ChangeLogTable from './ChangeLogTable'
import { SaveConfirmationModal } from './SaveConfirmationModal'
import { TimezonePanel } from './TimezonePanel'
import { InfoOutlined } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { NoChanges } from './NoChanges'

type Props = {
  dataSettings: DataSettings
  logEntries: DataSettingsLogEntry[]
  timezones: Timezone[]
}

export const DataSettingsContainer: React.FC<Props> = ({ dataSettings, logEntries, timezones }) => {
  const isMobile = useIsMobile()
  const Heading = isMobile ? H2 : H1
  const role = useUserRole()

  const [showSaveAlertModal, setShowSaveAlertModal] = useState(false)
  const [showNoChangesModal, setShowNoChangesModal] = useState(false)

  const [dataPayload, setDataPayload] = useState<DataSettingsPayload>({
    timezone: dataSettings.timezone || 'America - New York',
    startOfWeek: dataSettings.startOfWeek.label || 'Sunday',
    startOfYear: dataSettings.startOfYear.label || 'January',
    fiscalPeriod: dataSettings.fiscalPeriod || '4-4-5',
  })

  const handleChange = (key: string, value: string) => {
    setDataPayload({ ...dataPayload, [key]: value })
  }

  const hasChanges = () => {
    return (
      dataPayload.timezone !== dataSettings.timezone ||
      dataPayload.startOfWeek !== dataSettings.startOfWeek.label ||
      dataPayload.startOfYear !== dataSettings.startOfYear.label ||
      dataPayload.fiscalPeriod !== dataSettings.fiscalPeriod
    )
  }

  const handleSave = () => {
    hasChanges() ? setShowSaveAlertModal(true) : setShowNoChangesModal(true)
  }

  const listChanges = () => {
    const changes: string[] = []

    if (dataPayload.timezone !== dataSettings.timezone) {
      changes.push(`Timezone: ${dataSettings.timezone} → ${dataPayload.timezone}`)
    }

    if (dataPayload.startOfWeek !== dataSettings.startOfWeek.label) {
      changes.push(`Start of Week: ${dataSettings.startOfWeek.label} → ${dataPayload.startOfWeek}`)
    }

    if (dataPayload.startOfYear !== dataSettings.startOfYear.label) {
      changes.push(`Start of Year: ${dataSettings.startOfYear.label} → ${dataPayload.startOfYear}`)
    }

    if (dataPayload.fiscalPeriod !== dataSettings.fiscalPeriod) {
      changes.push(`Fiscal Period: ${dataSettings.fiscalPeriod} → ${dataPayload.fiscalPeriod}`)
    }

    return changes
  }

  return (
    <>
      <HeadingContainer>
        <Heading>Data Settings</Heading>
        <SubHeading>
          <StyledBody>
            Changes to data settings will not take effect until your store&apos;s next scheduled
            data refresh
          </StyledBody>
          <Tooltip
            text="Data refreshes are typically scheduled daily or hourly. Please reach out to your account manager for additional information."
            direction="bottom"
          >
            <IconButton size="small">
              <InfoOutlined fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </SubHeading>
        {(role === 'admin' || role === 'superuser' || role === 'data_admin') && (
          <ButtonContainer>
            <Button
              name="Save Changes"
              location="Data Setting Page"
              type="submit"
              purpose="primary"
              centreContent={true}
              onClick={handleSave}
            >
              Save Changes
            </Button>
          </ButtonContainer>
        )}
        {showSaveAlertModal && (
          <SaveConfirmationModal
            changes={listChanges()}
            data={dataPayload}
            onClose={() => setShowSaveAlertModal(false)}
          />
        )}
        {showNoChangesModal && <NoChanges onClose={() => setShowNoChangesModal(false)} />}
      </HeadingContainer>
      <DataSettingContainer>
        <TimezonePanel
          lookerTimezones={timezones}
          timezone={dataPayload.timezone}
          setTimezone={timezone => handleChange('timezone', timezone)}
        />

        <WeekDefinitionPanel
          weekStart={dataPayload.startOfWeek}
          setWeekStart={weekStart => handleChange('startOfWeek', weekStart)}
        />

        <FiscalYearAndPeriodPanel
          fiscalYear={dataPayload.startOfYear}
          setFiscalPeriodAndFiscalYear={(fiscalPeriod, fiscalYear) => {
            setDataPayload({ ...dataPayload, fiscalPeriod, startOfYear: fiscalYear })
          }}
          fiscalPeriod={dataPayload.fiscalPeriod}
        />

        <ChangeLogTable logEntries={logEntries} />
      </DataSettingContainer>
    </>
  )
}

const DataSettingContainer = styled.div`
  padding-top: 15px;
`

const HeadingContainer = styled.div`
  position: relative;
  padding-bottom: 30px;
`
const SubHeading = styled.div`
  display: flex;
  align-items: baseline;
  flex-direction: row;
  justify-content: flex-start;
`

const StyledBody = styled(Body)`
  color: ${p => p.theme.ContentTertiary};
`

const ButtonContainer = styled.span`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 10px;
  color: ${p => p.theme.ContentTertiary};
`
