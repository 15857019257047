import { FormRadioButton } from 'components/common'
import { H4 } from '@chordco/component-library'
import { FormField } from 'hooks'
import {
  Section,
  FlexRowContainer,
  StyledLabel as Label,
} from 'components/provisioning/styles/common'
import { OmsPlan } from 'types'

type Props = {
  fields: Record<string, FormField>
  selectedOmsPlan: string
  setSelectedOmsPlan: (plan: string) => void
}

const OmsPlanConfiguration: React.FC<Props> = ({ fields, selectedOmsPlan, setSelectedOmsPlan }) => {
  return (
    <Section>
      <H4>OMS Plan</H4>
      <Label>
        This setting is used to determine which Melody plan to apply when rendering our home page
        metric tiles.
      </Label>
      <FlexRowContainer>
        {Object.keys(OmsPlan).map(plan => {
          const slug = OmsPlan[plan as OmsPlan]

          return (
            <FormRadioButton
              key={plan}
              field={{
                type: 'string',
                name: plan,
                label: plan,
                value: selectedOmsPlan === slug,
                setValue: () => {
                  setSelectedOmsPlan(slug)
                  fields.omsPlan.setValue(slug)
                },
              }}
            />
          )
        })}
      </FlexRowContainer>
    </Section>
  )
}

export default OmsPlanConfiguration
