import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import { AudienceHeader } from 'components/chord-ai/audiences/AudienceHeader'
import { KeyInsights } from 'components/chord-ai/common/KeyInsights'
import { RecommendedActions } from 'components/chord-ai/common/RecommendedActions'
import ChordAiChatBot from 'components/chord-ai/chat-bot/ChatBot'
import AudienceOverview from 'components/chord-ai/audiences/AudienceOverview'
import { Audience } from 'components/chord-ai/types'
import { useChordAi } from 'components/chord-ai/ChordAiContext'
import { Loading } from 'components/common'

const AudienceDetails: React.FC = () => {
  const { slug } = useParams<{ slug: string }>()

  const [chatOpen, setChatOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [audience, setAudience] = useState<Audience | null>(null)

  const { chordAiClient } = useChordAi()

  const fetchAudience = useCallback(
    async (slug: string) => {
      try {
        setIsLoading(true)
        const audience = await chordAiClient?.getAudience(slug)
        if (audience) setAudience(audience)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching Audience:', error)
        throw error
      } finally {
        setIsLoading(false)
      }
    },
    [chordAiClient]
  )

  useEffect(() => {
    fetchAudience(slug)
  }, [slug])

  return (
    <>
      {isLoading && <Loading hideMask opacity={1} />}

      {!isLoading && !audience && <Typography variant="h4">Audience not found</Typography>}

      {!isLoading && audience && (
        <Box sx={{ width: '100%', pb: 4 }}>
          <AudienceHeader audience={audience} />
          <AudienceOverview audience={audience} />
          <KeyInsights insights={audience.keyInsights} />
          <RecommendedActions actions={audience.recommendedActions} />
        </Box>
      )}

      <ChordAiChatBot
        open={chatOpen}
        onOpen={() => setChatOpen(true)}
        onClose={() => setChatOpen(false)}
      />
    </>
  )
}

export default AudienceDetails
