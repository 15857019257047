import { DropdownProps, OptionProps } from '../index'
import { FormControl, Select, SelectChangeEvent, MenuItem } from '@mui/material'

export type DateDropdownPeriod = 'week' | 'month' | 'year'

type Props = Omit<DropdownProps, 'control' | 'options'>

const options: OptionProps[] = [
  { id: 'year', label: 'Year to date' },
  { id: 'month', label: 'Month to date' },
  { id: 'week', label: 'Week to date' },
]

export const DateDropdown: React.FC<Props> = ({ ...props }) => {
  return (
    <FormControl sx={{ minWidth: 300 }} size="small">
      <Select
        value={props.selected?.toString()}
        onChange={(event: SelectChangeEvent<string>) => {
          if (props.onSelect) props.onSelect(event.target.value)
        }}
      >
        {options.map(option => (
          <MenuItem key={option.id} value={option.id}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
