import React from 'react'
import { Box, Typography, Container } from '@mui/material'

const PlaceholderPage: React.FC<{ title: string }> = ({ title }) => {
  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          textAlign: 'center',
          mt: 8,
          p: 4,
          border: 1,
          borderColor: 'grey.300',
          borderRadius: 2,
          bgcolor: 'grey.100',
        }}
      >
        <Typography variant="h5" fontWeight="bold">
          {title}
        </Typography>
        <Typography variant="body1" sx={{ mt: 1, color: 'grey.600' }}>
          This section is under construction. Check back soon for updates!
        </Typography>
      </Box>
    </Container>
  )
}

export const AudiencesPlaceholder = () => <PlaceholderPage title="Audiences" />
export const NewFindingsPlaceholder = () => <PlaceholderPage title="New Findings" />
export const ReportsPlaceholder = () => <PlaceholderPage title="Reports" />
