import { AnalyticsServerEvent, IncomingEvent, IngestMessage } from 'components/chord-cdp/types'
import {
  ConfigurationObjectLink,
  DestinationConfig,
  RawDestinationEvent,
  RawIncomingEvent,
  ServiceConfig,
  StreamConfig,
} from 'types/cdp'
import { ParsedIncomingEventRow } from 'components/chord-cdp/events/incoming'
import { ParsedDestinationEventRow } from 'components/chord-cdp/events/destinations'
import moment from 'moment'

export const parseIncomingEventRows = (rows: RawIncomingEvent[]): ParsedIncomingEventRow[] => {
  return rows.map(row => {
    const body = JSON.parse(row.content.body) as IngestMessage
    const { type, httpPayload } = body || {}

    const pagePath = httpPayload?.context?.page?.path
    const pageUrl = httpPayload?.context?.page?.url

    // Extracting details for summary
    const event = httpPayload?.event || type
    const host = httpPayload?.context?.page?.host
    const referringDomain = httpPayload?.context?.page?.referring_domain
    const anonymousId = httpPayload?.anonymousId
    const email = httpPayload?.traits?.email
    const userId = httpPayload?.userId

    return {
      date: moment(row.date).utc().format('YYYY-MM-DD HH:mm:ss'),
      type,
      event,
      pagePath,
      pageUrl,
      summary: {
        email,
        userId,
        host,
        referringDomain,
        anonymousId,
      },
      rawEvent: row,
    }
  })
}

export const parseDestinationEventRows = (
  rows: RawDestinationEvent[]
): ParsedDestinationEventRow[] => {
  return rows.map(row => {
    const { date, level, content } = row

    const { processedRows, status } = content
    const { representation, error } = content

    const summary =
      error ||
      (representation?.schema
        ? 'Schema: ' +
          Object.entries(representation?.schema || {})
            .map(([k, _v]) => k)
            .join(', ')
        : representation?.response ?? '')

    return {
      date: moment(date).utc().format('YYYY-MM-DD HH:mm:ss'),
      level: level,
      batchSize: processedRows,
      status: status,
      tableName: representation?.name || '',
      summary: summary,
      rawEvent: row,
    }
  })
}

export const mapIncomingEvent = (ev: RawIncomingEvent): IncomingEvent => {
  let ingestPayload: any = {}
  let unparsedPayload = ''
  if (typeof ev.content.body === 'string') {
    unparsedPayload = ev.content.body
    try {
      ingestPayload = JSON.parse(ev.content.body)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(ev.content.body, e)
    }
  }
  const event = ingestPayload.httpPayload as AnalyticsServerEvent
  const context = event?.context

  return {
    id: ev.date.toString(),
    date: moment(ev.date).utc().format('YYYY-MM-DD HH:mm:ss'),
    ingestType: ingestPayload.ingestType,

    status: ev.content.status,
    error: ev.content.error,

    ingestPayload: ingestPayload,
    unparsedPayload: unparsedPayload,

    messageId: ingestPayload.messageId,
    type: ingestPayload.type,
    originDomain: ingestPayload.origin?.domain,
    writeKey: ingestPayload.writeKey,
    httpHeaders: ingestPayload.httpHeaders,

    event: event,
    context: context,

    host: context?.page?.host,
    pageURL: context?.page?.url,
    pagePath: context?.page?.path,
    pageTitle: context?.page?.title,
    userId: event?.userId,
    email: context?.traits?.email?.toString() || event?.traits?.email?.toString(),
    anonymousId: event?.anonymousId || '',

    referringDomain: context?.page?.referring_domain,

    destinations: [...(ev.content.asyncDestinations ?? []), ...(ev.content.tags ?? [])],
  }
}

export type MappedConnectionLink = {
  id: string
  source: StreamConfig | ServiceConfig | undefined
  destination: DestinationConfig | undefined
}

export const mapLinksToConnections = (
  streams: StreamConfig[],
  connectors: ServiceConfig[],
  destinations: DestinationConfig[],
  links: ConfigurationObjectLink[]
): MappedConnectionLink[] => {
  const streamLookup = Object.fromEntries(streams.map(source => [source.id, source]))
  const connectorLookup = Object.fromEntries(connectors.map(connector => [connector.id, connector]))
  const destinationLookup = Object.fromEntries(
    destinations.map(destination => [destination.id, destination])
  )

  const results = links
    // Filter out links with empty data
    .filter(link => link.data && Object.keys(link.data).length > 0)
    .map(link => {
      const source = streamLookup[link.fromId] || connectorLookup[link.fromId]
      const destination = destinationLookup[link.toId]

      return {
        id: link.id,
        source,
        destination,
      }
    })
    // Remove null values from the final array
    .filter(Boolean)

  return results as MappedConnectionLink[]
}
