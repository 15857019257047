import { FormInput, FormSecureInput } from 'components/common'
import { H4 } from '@chordco/component-library'
import { Section, Row } from 'components/provisioning/styles/common'
import { FormField } from 'hooks'

type Props = {
  fields: Record<string, FormField>
}

const SolidusConfiguration: React.FC<Props> = ({ fields }) => {
  return (
    <Section>
      <H4>Chord OMS Configuration</H4>
      <FormSecureInput field={fields.solidusApiKey} customWidth="100%" required />
      <FormInput field={fields.solidusApiHost} customWidth="100%" required />
      <Row>
        <FormInput field={fields.solidusStoreId} customWidth="100%" required />
        <FormInput field={fields.solidusTenantId} customWidth="100%" required />
      </Row>
    </Section>
  )
}

export default SolidusConfiguration
