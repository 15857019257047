import { useState } from 'react'
import { useParams } from 'react-router-dom'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import { Header } from 'components/common'
import Kpis from 'components/chord-ai/kpi/Kpis'
import Audiences from 'components/chord-ai/audiences/Audiences'
import ChordAiChatBot from 'components/chord-ai/chat-bot/ChatBot'
import { NewFindingsPlaceholder, ReportsPlaceholder } from 'components/chord-ai/placeholders'
import Settings from 'components/chord-ai/settings/Settings'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`cdp-tabpanel-${index}`}
      aria-labelledby={`cdp-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `ai-tab-${index}`,
    'aria-controls': `ai-tabpanel-${index}`,
  }
}

const ChordAi: React.FC = () => {
  const { slug } = useParams<{ slug: string }>()

  const [chatOpen, setChatOpen] = useState(false)

  const [tabIndex, setTabIndex] = useState(
    slug === 'kpis'
      ? 0
      : slug === 'audiences'
      ? 1
      : slug === 'new-findings'
      ? 2
      : slug === 'reports'
      ? 3
      : 0
  )

  const handleChange = (event: React.SyntheticEvent, tabIndex: number) => {
    setTabIndex(tabIndex)

    // also update the route/URL when the tab changes manually
    switch (tabIndex) {
      case 0:
        window.history.pushState({}, '', '/chord-ai/kpis')
        break
      case 1:
        window.history.pushState({}, '', '/chord-ai/audiences')
        break
      case 2:
        window.history.pushState({}, '', '/chord-ai/new-findings')
        break
      case 3:
        window.history.pushState({}, '', '/chord-ai/reports')
        break
    }
  }

  return (
    <Box sx={{ width: '100%', pb: 4 }}>
      <Header title="Chord AI" />
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabIndex} onChange={handleChange} aria-label="ai tabs">
          <Tab label="KPI Insights" {...a11yProps(0)} />
          <Tab label="Audiences" {...a11yProps(1)} />
          <Tab label="New Findings" {...a11yProps(2)} />
          <Tab label="Reports" {...a11yProps(3)} />
          <Tab label="Settings" {...a11yProps(4)} />
        </Tabs>
      </Box>
      <TabPanel value={tabIndex} index={0}>
        <Kpis openChatBot={() => setChatOpen(true)} />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <Audiences openChatBot={() => setChatOpen(true)} />
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <NewFindingsPlaceholder />
      </TabPanel>
      <TabPanel value={tabIndex} index={3}>
        <ReportsPlaceholder />
      </TabPanel>
      <TabPanel value={tabIndex} index={4}>
        <Settings />
      </TabPanel>
      <ChordAiChatBot
        open={chatOpen}
        onOpen={() => setChatOpen(true)}
        onClose={() => setChatOpen(false)}
      />
    </Box>
  )
}

export default ChordAi
