import React from 'react'
import {
  Fab,
  Modal,
  Box,
  Typography,
  TextField,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import SendIcon from '@mui/icons-material/Send'
import { SmartToy } from '@mui/icons-material'

interface ChordAiChatBotProps {
  open: boolean
  onOpen: () => void
  onClose: () => void
}

const ChordAiChatBot: React.FC<ChordAiChatBotProps> = ({ open, onOpen, onClose }) => {
  return (
    <>
      <Fab
        variant="extended"
        color="primary"
        aria-label="chord ai chat bot"
        onClick={onOpen}
        sx={{ position: 'fixed', bottom: 16, right: 20, textTransform: 'none' }}
      >
        Ask Chord AI
        <SmartToy sx={{ ml: 1 }} />
      </Fab>

      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: 'fixed',
            bottom: 80,
            right: 20,
            width: 300,
            bgcolor: 'background.paper',
            boxShadow: 3,
            p: 2,
            borderRadius: 2,
          }}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
            <Typography variant="h6">Ask Chord AI</Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <TextField
            fullWidth
            placeholder="Type your question..."
            InputProps={{
              endAdornment: (
                <IconButton>
                  <SendIcon />
                </IconButton>
              ),
            }}
          />
          <Typography variant="body2" mt={2} gutterBottom>
            Try asking:
          </Typography>
          <List>
            <ListItem disablePadding>
              <ListItemText primary="• Why did conversion drop last week?" />
            </ListItem>
            <ListItem disablePadding>
              <ListItemText primary="• What's our best performing segment?" />
            </ListItem>
            <ListItem disablePadding>
              <ListItemText primary="• How can we improve mobile conversion?" />
            </ListItem>
          </List>
        </Box>
      </Modal>
    </>
  )
}

export default ChordAiChatBot
