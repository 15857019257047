import { Typography } from '@mui/material'
import * as CredentialsForm from 'components/chord-cdp/configs/vendors'
import { DestinationType } from 'components/chord-cdp/destinations'
import { CredentialsConfig } from 'components/chord-cdp/configs'
import KeyValueList from 'components/chord-cdp/common/KeyValueList'

export const getFormComponent = (destinationTypeId: string) => {
  const formComponents = {
    amplitude: CredentialsForm.AmplitudeCredentialsForm,
    bigquery: CredentialsForm.BigQueryCredentialsForm,
    braze: CredentialsForm.BrazeCredentialsForm,
    clickhouse: CredentialsForm.ClickHouseCredentialsForm,
    'facebook-conversions': CredentialsForm.FacebookConversionsCredentialsForm,
    'facebook-pixel': CredentialsForm.FacebookPixelCredentialsForm,
    ga4: CredentialsForm.GoogleAnalyticsCredentialsForm,
    gcs: CredentialsForm.GcsCredentialsForm,
    hubspot: CredentialsForm.HubSpotCredentialsForm,
    intercom: CredentialsForm.IntercomCredentialsForm,
    june: CredentialsForm.JuneSdkCredentialsForm,
    mixpanel: CredentialsForm.MixpanelCredentialsForm,
    mongodb: CredentialsForm.MongodbCredentialsForm,
    mysql: CredentialsForm.MysqlCredentialsForm,
    postgres: CredentialsForm.PostgresCredentialsForm,
    posthog: CredentialsForm.PosthogCredentialsForm,
    redshift: CredentialsForm.RedshiftCredentialsForm,
    s3: CredentialsForm.S3CredentialsForm,
    'segment-proxy': CredentialsForm.SegmentCredentialsForm,
    snowflake: CredentialsForm.SnowflakeCredentialsForm,
    webhook: CredentialsForm.WebhookCredentialsForm,
    tag: CredentialsForm.TagCredentialsForm,
    logrocket: CredentialsForm.LogRocketCredentialsForm,
    'ga4-tag': CredentialsForm.GaDeviceDestinationCredentialsForm,
    gtm: CredentialsForm.GtmDeviceDestinationCredentialsForm,
    klaviyo: CredentialsForm.KlaviyoCredentialsForm,
    attentive: CredentialsForm.AttentiveCredentialsForm,
    'pinterest-tag': CredentialsForm.PinterestTagCredentialsForm,
    'pinterest-conversions': CredentialsForm.PinterestConversionsCredentialsForm,
  }

  return formComponents[destinationTypeId] || null
}

/**
 * Dynamically render the specialized form based on the destinationType
 */
export const renderFormComponent = (
  destinationType: DestinationType,
  formData: Record<string, any>,
  handleChange: (field: string, value: any) => void
): JSX.Element => {
  if (destinationType.id === 'devnull') {
    return (
      <Typography>
        This destination is used for testing purposes and does not require any configuration.
      </Typography>
    )
  }

  const FormComponent = getFormComponent(destinationType.id)
  return FormComponent ? (
    <FormComponent values={formData} onChange={handleChange} />
  ) : (
    <Typography>Unsupported configuration type: {destinationType.title}</Typography>
  )
}

export const transformFormData = (
  formData: Record<string, any>,
  credentials: CredentialsConfig
) => {
  const transformedData: Record<string, any> = { ...formData }

  for (const [key, config] of Object.entries(credentials || {})) {
    if (config.componentProps?.multiline) {
      // Multiline textfields need to be split by newline (also remove empty strings)
      transformedData[key] = Array.isArray(formData[key])
        ? formData[key].filter(Boolean)
        : formData[key].split('\n').filter(Boolean)
    } else if (config.componentProps?.type === 'number') {
      // Number fields need to be parsed as integers
      transformedData[key] = parseInt(formData[key])
    } else if (config.renderComponent === KeyValueList) {
      // Key-value lists need to be transformed into an object
      transformedData[key] = formData[key]
        ? formData[key].reduce((acc: Record<string, any>, param: { key: string; value: any }) => {
            acc[param.key] = param.value
            return acc
          }, {})
        : {}
    } else {
      // All other fields can be copied as is
      transformedData[key] = formData[key]
    }
  }

  return transformedData
}
