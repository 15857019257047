import React from 'react'
import { Box } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { Button } from '@chordco/component-library'
import { ArrowLeft } from '@chordco/component-library/components/icons/standardIcons/ArrowLeft'
import { Audience } from 'components/chord-ai/types'
import { Header } from 'components/common'

interface AudienceHeaderProps {
  audience: Audience
}

export const AudienceHeader: React.FC<AudienceHeaderProps> = ({ audience }) => {
  const history = useHistory()
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Header title={audience.name} subtitle={audience.description} hideBottomBorder />
      <Button purpose="tertiary" icon={ArrowLeft} onClick={() => history.push('/chord-ai/audiences')}>
        Back
      </Button>
    </Box>
  )
}
