import ChordAiClient, { ChordAiClientOptions } from 'api/chord_ai/client'
import { createContext, useContext, ReactNode, useMemo } from 'react'

interface ChordAiProviderProps {
  clientOptions: ChordAiClientOptions
  children: ReactNode
}

type ChordAiContextValue = {
  chordAiClient: ChordAiClient | null
}

const ChordAiContext = createContext<ChordAiContextValue | null>(null)

export const useChordAi = () => {
  const contextValue = useContext(ChordAiContext)
  if (!contextValue) {
    throw new Error('useChordAi must be used within a ChordAiProvider')
  }
  return contextValue
}

export const ChordAiProvider: React.FC<ChordAiProviderProps> = ({ clientOptions, children }) => {
  const chordAiClient = new ChordAiClient(clientOptions)

  const contextValue: ChordAiContextValue = useMemo(() => {
    return {
      chordAiClient,
    }
  }, [chordAiClient])

  return <ChordAiContext.Provider value={contextValue}>{children}</ChordAiContext.Provider>
}
