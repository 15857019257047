import { useMemo } from 'react'
import { useExperiments } from 'hooks'
import useUserRole from './useUserRole'

interface NavLinkFilter {
  section: string
  slug: string
  childSlug?: string
}

/**
 * This function returns an array of filters to be applied to our navigation links. Please ensure
 * that `slugs` are unique and properly match the `slug` property configured in the `links.ts` file.
 */
const useFilteredNavLinks = (): NavLinkFilter[] => {
  const role = useUserRole()
  const omsOnly = role === 'oms_admin'
  const dataOnly = role === 'data_admin'

  const sysHealthTreatment = useExperiments('system_health')
  const productsTreatment = useExperiments('hub_products_nav_link')
  const subscriptionsTreatment = useExperiments('hub_subscriptions_nav_link')
  const stockTreatment = useExperiments('hub_stock_nav_link')
  const contentfulTreatment = useExperiments('hub_contentful_nav_link')
  const integrationsTreatment = useExperiments('hub_integrations_nav_link')
  const cdpTreatment = useExperiments('hub_hide_cdp_nav_link')
  const configUiTreatment = useExperiments('hub_config_ui_status')
  const chordCdpTreatment = useExperiments('hub_chord_cdp')
  const chordAiTreatment = useExperiments('hub_chord_ai')
  const dataSettingsTreatment = useExperiments('hub_data_settings')

  const filters = useMemo<NavLinkFilter[]>(() => {
    const filters: NavLinkFilter[] = []

    if (sysHealthTreatment && !sysHealthTreatment.enabled) {
      filters.push({ section: 'hubNavLinks', slug: 'hub-nav-system-health' })
    }

    if (productsTreatment && !productsTreatment.enabled) {
      filters.push({ section: 'storeNavLinks', slug: 'store-nav-products' })
    }

    if (subscriptionsTreatment && !subscriptionsTreatment.enabled) {
      filters.push({
        section: 'storeNavLinks',
        slug: 'store-nav-subscriptions',
      })
    }

    if (stockTreatment && !stockTreatment.enabled) {
      filters.push({ section: 'storeNavLinks', slug: 'store-nav-stock' })
    }

    if (contentfulTreatment && !contentfulTreatment.enabled) {
      filters.push({ section: 'storeNavLinks', slug: 'store-nav-contentful' })
    }

    if ((integrationsTreatment && !integrationsTreatment.enabled) || omsOnly) {
      filters.push({ section: 'hubNavLinks', slug: 'hub-nav-integrations' })
    }

    if (cdpTreatment && !cdpTreatment.enabled) {
      filters.push({ section: 'hubNavLinks', slug: 'hub-nav-segment' })
    }

    if (configUiTreatment && !configUiTreatment.enabled) {
      filters.push({
        section: 'dataNavLinks',
        slug: 'data-nav-model-amplifications',
      })
    }

    if (chordCdpTreatment && !chordCdpTreatment.enabled) {
      filters.push({ section: 'dataNavLinks', slug: 'data-nav-chord-cdp' })
    }

    if (chordAiTreatment && !chordAiTreatment.enabled) {
      filters.push({ section: 'dataNavLinks', slug: 'data-nav-chord-ai' })
    }

    if (dataSettingsTreatment && !dataSettingsTreatment.enabled) {
      filters.push({
        section: 'dataNavLinks',
        slug: 'data-nav-data-settings',
      })
    }

    // OMS features hidden for data-only roles
    if (dataOnly) {
      const omsSlugs = [
        'store-nav-orders',
        'store-nav-customers',
        'store-nav-promotions',
        'store-nav-settings',
        'store-nav-imports',
        'store-nav-subscriptions',
        'store-nav-stock',
        'store-nav-products',
        'store-nav-contentful',
      ]
      omsSlugs.forEach(slug => filters.push({ section: 'storeNavLinks', slug }))
    }

    return filters
  }, [
    sysHealthTreatment,
    productsTreatment,
    subscriptionsTreatment,
    stockTreatment,
    contentfulTreatment,
    integrationsTreatment,
    cdpTreatment,
    configUiTreatment,
    chordCdpTreatment,
    dataSettingsTreatment,
    chordAiTreatment,
    role,
  ])

  return filters
}

export default useFilteredNavLinks
