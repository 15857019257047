import React from 'react'
import { Box } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { Button } from '@chordco/component-library'
import { Kpi } from 'components/chord-ai/types'
import { Header } from 'components/common'
import { ArrowLeft } from '@chordco/component-library/components/icons/standardIcons/ArrowLeft'

interface KpiHeaderProps {
  kpi: Kpi
}

export const KpiHeader: React.FC<KpiHeaderProps> = ({ kpi }) => {
  const history = useHistory()
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Header title={kpi.name} subtitle={kpi.description} hideBottomBorder />
      <Button purpose="tertiary" icon={ArrowLeft} onClick={() => history.push('/chord-ai/kpis')}>
        Back
      </Button>
    </Box>
  )
}
