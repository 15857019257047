export const formatKpiValue = (value: number | null | undefined, unit: '%' | '$' | string) => {
  if (value == null) return '-'

  if (unit === '%') {
    return `${value}%`
  }

  if (unit === '$') {
    return `$${value.toLocaleString(undefined, {
      minimumFractionDigits: value % 1 === 0 ? 0 : 2,
      maximumFractionDigits: 2,
    })}`
  }

  return `${value} ${unit || ''}`.trim()
}
