import React from 'react'
import { Box, Card, CardContent, Typography, Tooltip } from '@mui/material'
import { RecommendedAudience } from 'components/chord-ai/types'
import { standardIcons, Button } from '@chordco/component-library'
import GeneratedByChordAi from 'components/chord-ai/common/GeneratedByChordAi'

interface RecommendedAudiencesProps {
  audiences: RecommendedAudience[]
}

export const RecommendedAudiences: React.FC<RecommendedAudiencesProps> = ({ audiences }) => {
  return (
    <Card sx={{ p: 0, mt: 2, border: 1, borderColor: 'grey.200' }}>
      <CardContent>
        <Box display="flex" justifyContent="flex-start" alignItems="center" gap={1}>
          <Typography variant="h6" fontWeight="bold">
            Audiences to Activate
          </Typography>
          <GeneratedByChordAi />
        </Box>

        {/* Scrollable Container */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 2,
            mt: 1,
            overflowX: 'auto',
            paddingBottom: 1,
          }}
        >
          {audiences.map((audience, index) => (
            <Box
              key={index}
              sx={{
                width: 300,
                height: 180,
                border: 1,
                borderColor: 'grey.200',
                borderRadius: 2,
                padding: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                flexShrink: 0, // Prevents shrinking
              }}
            >
              <Typography variant="body1" fontWeight="bold" noWrap>
                {audience.name}
              </Typography>
              <Tooltip title={audience.description} placement="top">
                <Typography
                  variant="body2"
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: 2, // Limits text to 2 lines
                    WebkitBoxOrient: 'vertical',
                    whiteSpace: 'normal', // Allows text to wrap
                    flexGrow: 1, // Takes available space
                  }}
                >
                  {audience.description}
                </Typography>
              </Tooltip>

              <Button purpose="tertiary" icon={standardIcons.Share} size="small" centreContent>
                Export to {audience.engagementPlatform}
              </Button>
              <Button purpose="tertiary" icon={standardIcons.LineChart} size="small" centreContent>
                Explore
              </Button>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  )
}
