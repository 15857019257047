import React from 'react'
import { Card, CardContent, Typography, Chip, Box, Stack } from '@mui/material'
import { CheckCircle, Error, Info, QueryStats, Warning } from '@mui/icons-material'
import { Kpi } from 'components/chord-ai/types'
import SmallTrendChart from 'components/chord-ai/charts/SmallTrendChart'
import { Button } from '@chordco/component-library'
import { formatKpiValue } from 'components/chord-ai/utils'
import GeneratedByChordAi from 'components/chord-ai/common/GeneratedByChordAi'

interface KpiCardProps {
  kpi: Kpi
  onAnalyzeClick: (kpi: Kpi) => void
}

const KpiCard: React.FC<KpiCardProps> = ({ kpi, onAnalyzeClick }) => {
  const renderInsightIcon = (type: string) => {
    switch (type) {
      case 'check':
        return <CheckCircle fontSize="small" color="success" />
      case 'info':
        return <Info fontSize="small" color="info" />
      case 'alert':
        return <Error fontSize="small" color="error" />
      case 'warning':
        return <Warning fontSize="small" color="warning" />
      default:
        return null
    }
  }

  return (
    <Card sx={{ p: 0, border: 1, borderColor: 'grey.200' }}>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" fontWeight={600}>
            {kpi.name} {kpi.aiGenerated ? <GeneratedByChordAi /> : null}
          </Typography>
          <Chip label={formatKpiValue(kpi.value, kpi.unit)} variant="filled" />
        </Box>

        <Typography variant="body2" color="text.secondary">
          Updated At: {kpi.updatedAt}
        </Typography>

        <Box
          mt={2}
          mb={2}
          height={100}
          sx={{
            backgroundColor: 'grey.100',
            padding: 1,
            borderRadius: 1,
          }}
        >
          <SmallTrendChart
            data={kpi.trendGraph.data}
            labels={kpi.trendGraph.labels}
            unit={kpi.unit}
          />
        </Box>

        <Stack spacing={1} mt={2}>
          {kpi.keyInsights.map((insight, index) => (
            <Box key={index} display="flex" alignItems="center">
              {renderInsightIcon(insight.type)}
              <Typography variant="body2" ml={1}>
                {insight.name}
              </Typography>
            </Box>
          ))}
        </Stack>

        <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
          <Chip
            label={`${kpi.recommendedActions.length} recommended actions`}
            variant="outlined"
            size="small"
            color="default"
          />
          <Button
            purpose="primary"
            size="small"
            icon={QueryStats}
            onClick={() => onAnalyzeClick(kpi)}
          >
            Analyze Further
          </Button>
        </Box>
      </CardContent>
    </Card>
  )
}

export default KpiCard
