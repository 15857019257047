import React, { useState } from 'react'
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  AreaChart,
  Area,
  PieChart,
  Pie,
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Cell,
  CartesianGrid,
} from 'recharts'
import { formatKpiValue } from 'components/chord-ai/utils'
import { Box, ToggleButtonGroup, ToggleButton } from '@mui/material'
import BarChartIcon from '@mui/icons-material/BarChart'
import ShowChartIcon from '@mui/icons-material/ShowChart'
import TimelineIcon from '@mui/icons-material/Timeline'
import PieChartIcon from '@mui/icons-material/PieChart'
import BubbleChartIcon from '@mui/icons-material/BubbleChart'

interface LargeTrendChartProps {
  data: number[]
  labels: string[]
  unit?: string
}

interface ChartData {
  name: string
  value: number
}

const LargeTrendChart: React.FC<LargeTrendChartProps> = ({ data, labels, unit }) => {
  const [chartType, setChartType] = useState<'bar' | 'line' | 'area' | 'pie' | 'scatter'>(
    unit === '%' ? 'bar' : 'line'
  )

  const handleChartTypeChange = (_: React.MouseEvent<HTMLElement>, newType: string | null) => {
    if (newType) setChartType(newType as typeof chartType)
  }

  const chartData = data.map((value, index) => ({
    name: labels[index],
    value,
  }))

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042']

  const renderBarChart = (data: ChartData[]) => (
    <BarChart data={data}>
      <XAxis dataKey="name" tick={{ fontSize: 9 }} />
      <YAxis tick={{ fontSize: 9 }} width={30} />
      <Tooltip formatter={(value: number) => formatKpiValue(value, unit || '')} />
      <Bar dataKey="value" isAnimationActive fill="#4caf50" />
    </BarChart>
  )

  const renderLineChart = (data: ChartData[]) => {
    const stroke = unit === '$' ? '#ff9800' : '#8884d8'
    return (
      <LineChart data={data}>
        <XAxis dataKey="name" tick={{ fontSize: 9 }} />
        <YAxis tick={{ fontSize: 9 }} width={30} />
        <Tooltip formatter={(value: number) => formatKpiValue(value, unit || '')} />
        <Line
          type="monotone"
          dataKey="value"
          stroke={stroke}
          dot={{ stroke: stroke, strokeWidth: 2, r: 3 }}
          isAnimationActive
        />
      </LineChart>
    )
  }

  const renderAreaChart = (data: ChartData[]) => (
    <AreaChart data={data}>
      <XAxis dataKey="name" tick={{ fontSize: 9 }} />
      <YAxis tick={{ fontSize: 9 }} width={30} />
      <Tooltip formatter={(value: number) => formatKpiValue(value, unit || '')} />
      <Area
        type="monotone"
        dataKey="value"
        stroke="#8884d8"
        fill="#8884d8"
        fillOpacity={0.5}
        isAnimationActive
      />
    </AreaChart>
  )

  const renderPieChart = (data: ChartData[]) => (
    <PieChart width={600} height={600}>
      <Pie
        data={data}
        dataKey="value"
        nameKey="name"
        cx="50%"
        cy="50%"
        outerRadius={120}
        innerRadius={60}
        labelLine={false}
        label={entry => `${entry.name} ${(entry.percent * 100).toFixed(0)}%`}
        fill="#8884d8"
      >
        {data.map((_entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Tooltip formatter={(value: number) => formatKpiValue(value, unit || '')} />
    </PieChart>
  )

  const renderScatterChart = (data: ChartData[]) => (
    <ScatterChart>
      <CartesianGrid />
      <XAxis dataKey="name" tick={{ fontSize: 9 }} />
      <YAxis dataKey="value" tick={{ fontSize: 9 }} width={30} />
      <Tooltip
        cursor={{ strokeDasharray: '3 3' }}
        formatter={(value: any) =>
          typeof value === 'number' ? formatKpiValue(value, unit || '') : value
        }
      />
      <Scatter data={data} fill="#ff5722" opacity={0.7} isAnimationActive />
    </ScatterChart>
  )

  const renderChart = (type: string, data: ChartData[]) => {
    switch (type) {
      case 'bar':
        return renderBarChart(data)
      case 'line':
        return renderLineChart(data)
      case 'area':
        return renderAreaChart(data)
      case 'pie':
        return renderPieChart(data)
      case 'scatter':
        return renderScatterChart(data)
      default:
        // eslint-disable-next-line no-console
        console.error('Invalid chart type:', type)
        return renderLineChart(data)
    }
  }

  return (
    <Box>
      <Box display="flex" justifyContent="flex-end" mb={1}>
        <ToggleButtonGroup
          value={chartType}
          exclusive
          onChange={handleChartTypeChange}
          size="small"
        >
          <ToggleButton value="bar">
            <BarChartIcon />
          </ToggleButton>
          <ToggleButton value="line">
            <ShowChartIcon />
          </ToggleButton>
          <ToggleButton value="area">
            <TimelineIcon />
          </ToggleButton>
          <ToggleButton value="pie">
            <PieChartIcon />
          </ToggleButton>
          <ToggleButton value="scatter">
            <BubbleChartIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <ResponsiveContainer
        width="100%"
        height={400}
        style={{ paddingTop: '10px', marginTop: '10px' }}
      >
        {renderChart(chartType, chartData)}
      </ResponsiveContainer>
    </Box>
  )
}

export default LargeTrendChart
