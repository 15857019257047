import { useCallback, useEffect, useRef } from 'react'
import { useAuthData } from 'redux/state/auth'
import { useNotifications } from 'redux/state/notifications'
import { useHistory } from 'react-router'
import { LoadingPage } from '../common'
import { v4 as generateUUID } from 'uuid'
import { useIsIdle } from 'redux/utils'
import { getQueryStringValues } from 'utils'
import useMixpanel from 'hooks/useMixpanel'

export const Authenticate: React.FC = () => {
  const queryStringValues = getQueryStringValues(location.search, true)
  const { login, state } = useAuthData()
  const { addNotification } = useNotifications()
  const isLoginIdle = useIsIdle('login')

  const history = useHistory()
  const { identify } = useMixpanel()

  /**
   * Create a ref to track if login has already been triggered.
   *
   * This is needed because React Strict Mode (see src/index.js)
   * intentionally double-invokes lifecycle methods (including useEffect)
   * in development to identify unsafe side effects.
   *
   * To prevent `login` from being called twice in development (yarn start),
   * we use a ref to ensure that the login process only happens once, even
   * if useEffect runs multiple times. This is to prevent the token from
   * being used twice, which would cause an error.
   *
   * Note: this problem will not happen when running the app in production
   * mode (yarn build && yarn serve).
   */
  const hasLoggedInRef = useRef(false)

  const memoizedLogin = useCallback(
    (token, stytchTokenType) => {
      if (!hasLoggedInRef.current) {
        hasLoggedInRef.current = true
        login({ token, stytchTokenType })
      }
    },
    [login]
  )

  const memoizedAddNotification = useCallback(
    (id, message) => {
      addNotification({ id, type: 'caution', message })
    },
    [addNotification]
  )

  const memoizedIdentify = useCallback(() => {
    if (state.user) {
      identify(state.user)
    }
  }, [identify, state.user])

  useEffect(() => {
    const handleAuthenticatedUser = () => {
      if (isLoginIdle) {
        // invite/login link clicked when another user is already logged in
        const id = generateUUID() as string
        memoizedAddNotification(id, 'A user is already logged in. Please, log out and try again.')
        history.push('')
      } else if (!state.user?.firstName || !state.user?.lastName) {
        // invited user hasn't entered their name
        history.push('accept')
      } else {
        memoizedIdentify()
        // an authenticated user hit the /authenticate page
        history.push('')
      }
    }

    if (state.isAuthenticated) {
      handleAuthenticatedUser()
    } else if (isLoginIdle) {
      // an unauthenticated user is logging in
      const { token, stytchTokenType = '' } = queryStringValues
      memoizedLogin(token, stytchTokenType)
    }
  }, [
    state.isAuthenticated,
    state.user,
    isLoginIdle,
    queryStringValues,
    memoizedLogin,
    memoizedAddNotification,
    memoizedIdentify,
    history,
  ])

  return <LoadingPage />
}
