import { useTenantsData } from 'redux/state/tenants'
import { useUsersData } from 'redux/state/users'

export const useTenantSeatLimitHelper = () => {
  const {
    state: { users },
  } = useUsersData()
  const {
    state: { currentTenant },
  } = useTenantsData()

  if (!currentTenant) {
    throw new Error('Current Tenant not found!')
  }

  const { userSeatLimit, userSeatLimitRequestFulfilled } = currentTenant
  const tenantTeamMembers = users.filter(({ email }) => !/chord\.co.*/.test(email || ''))
  const teamMemberCount = tenantTeamMembers.length
  const tenantSeatCountLimitReached = teamMemberCount >= userSeatLimit

  return {
    tenantTeamMembers,
    numberOfSeatsLeft: userSeatLimit - teamMemberCount,
    tenantSeatCountLimitReached,
    tenantSeatCountBelowLimit: !tenantSeatCountLimitReached,
    hasTenantReachedSeatLimit: tenantSeatCountLimitReached && userSeatLimitRequestFulfilled,
    hasTenantRequestedSeatLimitIncrease:
      tenantSeatCountLimitReached && !userSeatLimitRequestFulfilled,
  }
}
