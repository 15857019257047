import { FormSecureInput } from 'components/common'
import { H4 } from '@chordco/component-library'
import { FormField } from 'hooks'
import { Section, StyledLabel as Label } from 'components/provisioning/styles/common'

type Props = {
  fields: Record<string, FormField>
}

const DataActivationsConfiguration: React.FC<Props> = ({ fields }) => {
  return (
    <Section>
      <H4>Data Activations (optional)</H4>
      <Label>
        You can retrieve your workspace API key from the Census workspace settings under the{' '}
        <b>API Access</b> section.
      </Label>
      <FormSecureInput field={fields.censusApiKey} customWidth="100%" />
    </Section>
  )
}

export default DataActivationsConfiguration
