export enum Plan {
  Autonomy = 'autonomy',
  Performance = 'performance',
  DataOnly = 'data_only',
}

export type StoreEnvironment = 'production' | 'staging' | 'test'

export type TenantStore = {
  id: number
  chordUuid: string
  plan: Plan
  environments: StoreEnvironment[]
}

export type Tenant = {
  id: number
  chordUuid: string
  name: string
  stores: TenantStore[]
  userSeatLimit: number
  userSeatLimitRequestFulfilled: boolean
  ssoEnabled: boolean
  metadata?: { [key: string]: any }
}

export type TenantsState = {
  tenants: Tenant[]
  currentStoreIsAutonomy: boolean
  currentTenant?: Tenant
  currentStore?: TenantStore
}
