import React from 'react'
import { Card, CardContent, Box, Typography } from '@mui/material'
import { Audience } from 'components/chord-ai/types'
import { Button, standardIcons } from '@chordco/component-library'

interface AudienceOverviewProps {
  audience: Audience
}

const AudienceOverview: React.FC<AudienceOverviewProps> = ({ audience }) => {
  return (
    <Card sx={{ p: 0, mt: 2, border: 1, borderColor: 'grey.200' }}>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box>
            <Typography variant="h6" fontWeight="bold">
              Audience Overview
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              {audience.users.toLocaleString()} customers
            </Typography>
          </Box>
          <Button purpose="primary" size="small" icon={standardIcons.Share}>
            Export Segment
          </Button>
        </Box>
        <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
          {audience.stats.map((stat, index) => (
            <Card key={index} sx={{ flex: 1, p: 2, backgroundColor: 'grey.100' }}>
              <Typography variant="subtitle2" color="text.secondary">
                {stat.label}
              </Typography>
              <Typography variant="h6">{stat.value}</Typography>
            </Card>
          ))}
        </Box>
      </CardContent>
    </Card>
  )
}

export default AudienceOverview
