import { H4 } from '@chordco/component-library'
import { FormField } from 'hooks'
import { FormInput } from 'components/common'
import {
  Section,
  StyledCheckbox,
  CheckboxList,
  StyledLabel as Label,
} from 'components/provisioning/styles/common'
import { ChordDataConnector } from 'types'

type Props = {
  fields: Record<string, FormField>
  dataConnectors: ChordDataConnector[]
  selectedConnectors: string[]
  setSelectedConnectors: (connectors: string[]) => void
}

const DataSourceConfiguration: React.FC<Props> = ({
  fields,
  dataConnectors,
  selectedConnectors,
  setSelectedConnectors,
}) => {
  return (
    <Section>
      <H4>Data Sources (optional)</H4>
      <Label>
        You can retrieve your Destination Group ID on the destination page of your Fivetran
        dashboard.
      </Label>
      <FormInput field={fields.fivetranGroupId} customWidth="100%" />

      {dataConnectors.length > 0 && (
        <>
          <Label>Available Connectors</Label>
          <CheckboxList>
            {dataConnectors
              .filter(connector => connector.active)
              .map((connector, i) => (
                <StyledCheckbox
                  key={i}
                  field={{
                    name: 'Connector',
                    label: connector.name,
                    type: 'boolean',
                    value: selectedConnectors.includes(connector.slug),
                    setValue: val => {
                      let updatedConnectors

                      if (val) {
                        updatedConnectors = [...selectedConnectors, connector.slug]
                        fields.filterConnectors.setValue(
                          updatedConnectors.concat(connector.slug).join(',')
                        )
                      } else {
                        updatedConnectors = selectedConnectors.filter(c => c !== connector.slug)
                        fields.filterConnectors.setValue(updatedConnectors.join(','))
                      }

                      setSelectedConnectors(updatedConnectors)
                    },
                  }}
                />
              ))}
          </CheckboxList>
        </>
      )}
    </Section>
  )
}

export default DataSourceConfiguration
