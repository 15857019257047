import { hubClient } from 'api/http'
import { Kpi, AudienceCategory, Audience, CacheBust } from 'components/chord-ai/types'

export interface ChordAiClientOptions {
  tenantId: number
  storeId: number
}

class ChordAiClient {
  private tenantId: number
  private storeId: number
  private baseURL: string

  constructor(options: ChordAiClientOptions) {
    this.tenantId = options.tenantId
    this.storeId = options.storeId
    this.baseURL = `tenants/${this.tenantId}/stores/${this.storeId}/chord_ai`
  }

  async getKpis(): Promise<Kpi[]> {
    try {
      return await hubClient.get(`${this.baseURL}/kpis`)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching KPIs:', error)
      throw error
    }
  }

  async getKpi(slug: string): Promise<Kpi> {
    try {
      return await hubClient.get(`${this.baseURL}/kpis/${slug}`)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching KPI:', error)
      throw error
    }
  }

  async getAudienceCategories(): Promise<AudienceCategory[]> {
    try {
      return await hubClient.get(`${this.baseURL}/audiences`)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching audience categories:', error)
      throw error
    }
  }

  async getAudience(slug: string): Promise<Audience> {
    try {
      return await hubClient.get(`${this.baseURL}/audiences/${slug}`)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching audience:', error)
      throw error
    }
  }

  async clearCache(target: string): Promise<CacheBust> {
    try {
      return await hubClient.delete(`${this.baseURL}/cache/bust?target=${target}`)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error clearing cache:', error)
      throw error
    }
  }
}

export default ChordAiClient
