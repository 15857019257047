import { createSlice } from '@reduxjs/toolkit'
import { useStateSliceAndDispatch } from '../utils'
import { DataSettingsState, DataSettingsPayload } from 'types'
import { useTenantsData } from 'redux/state/tenants'
import { reduxApiClient } from 'redux/api'

const SLICE_NAME = 'dataSettings'
const initialState: DataSettingsState = {
  dataSettings: null,
  timezones: null,
  logEntries: [],
}

const { getDataSettings, getDataTimezones, getDataSettingsChangeLog, updateDataSettings } =
  reduxApiClient

const dataSettingsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetDataSettings: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(getDataSettings.fulfilled, (state, action) => {
      state.dataSettings = action.payload.data
    })

    builder.addCase(getDataTimezones.fulfilled, (state, action) => {
      state.timezones = action.payload.data
    })

    builder.addCase(getDataSettingsChangeLog.fulfilled, (state, action) => {
      state.logEntries = action.payload.data
    })
  },
})

export default dataSettingsSlice.reducer

export const useDataSettingsData = () => {
  const { dispatch, state } = useStateSliceAndDispatch(SLICE_NAME)

  const {
    state: { currentTenant },
  } = useTenantsData()

  const tenantId = currentTenant?.id

  if (!tenantId) {
    return { state }
  }

  return {
    state,
    getDataSettings: () => {
      dispatch(getDataSettings(tenantId))
    },
    getDataTimezones: () => {
      dispatch(getDataTimezones(tenantId))
    },
    getDataSettingsChangeLog: () => {
      dispatch(getDataSettingsChangeLog(tenantId))
    },
    updateDataSettings: (data: DataSettingsPayload) =>
      dispatch(updateDataSettings({ tenantId, data })).then(() => {
        dispatch(getDataSettings(tenantId))
        dispatch(getDataSettingsChangeLog(tenantId))
      }),
  }
}
