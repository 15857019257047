import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Typography, Box, Grid, Chip } from '@mui/material'
import AudienceCard from 'components/chord-ai/audiences/AudienceCard'
import { NavigationContext } from 'components/AuthenticatedRoutes'
import { Audience, AudienceCategory } from 'components/chord-ai/types'
import GeneratedByChordAi from 'components/chord-ai/common/GeneratedByChordAi'
import ChatBotMessage from 'components/chord-ai/chat-bot/ChatBotMessage'
import { useChordAi } from 'components/chord-ai/ChordAiContext'
import { Loading } from 'components/common'

interface AudiencesProps {
  openChatBot: () => void
}

const Audiences: React.FC<AudiencesProps> = ({ openChatBot }) => {
  const history = useHistory()

  const [isLoading, setIsLoading] = useState(true)
  const [audienceCategories, setAudienceCategories] = useState<AudienceCategory[]>([])

  const { chordAiClient } = useChordAi()

  const fetchAudiences = useCallback(async () => {
    try {
      setIsLoading(true)
      const categories = await chordAiClient?.getAudienceCategories()
      if (categories) setAudienceCategories(categories)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching audiences:', error)
      throw error
    } finally {
      setIsLoading(false)
    }
  }, [chordAiClient])

  useEffect(() => {
    fetchAudiences()
  }, [])

  const handleAudienceClick = (audience: Audience) => {
    history.push(`/chord-ai/audiences/${audience.slug}`)
  }

  return (
    <NavigationContext.Consumer>
      {({ navOpen }) => (
        <Box sx={{ p: 2 }}>
          {isLoading && <Loading hideMask opacity={1} />}

          {!isLoading && audienceCategories.length === 0 && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '50vh',
              }}
            >
              No audiences found
            </Box>
          )}

          {!isLoading && audienceCategories.length > 0 && (
            <>
              {audienceCategories.map((category, index) => (
                <Box key={index} sx={{ mb: 3 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography variant="h6" fontWeight="bold">
                      {category.category}
                    </Typography>
                    {category.aiGenerated && <GeneratedByChordAi />}
                    {category.new && <Chip label="New" size="small" sx={{ bgcolor: 'grey.200' }} />}
                  </Box>
                  {category.description && (
                    <ChatBotMessage message={category.description} onAskQuestion={openChatBot} />
                  )}
                  <Grid container spacing={2} sx={{ mt: 0 }}>
                    {category.audiences.map(audience => (
                      <Grid
                        item
                        key={audience.id}
                        xs={navOpen ? 14 : 12}
                        md={navOpen ? 12 : 6}
                        lg={navOpen ? 6 : 4}
                      >
                        <AudienceCard audience={audience} onClick={handleAudienceClick} />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              ))}
            </>
          )}
        </Box>
      )}
    </NavigationContext.Consumer>
  )
}

export default Audiences
