import { useState } from 'react'
import { Button } from '@chordco/component-library'
import { Box, Container, Typography } from '@mui/material'
import { useChordAi } from 'components/chord-ai/ChordAiContext'
import useUserRole from 'redux/hooks/useUserRole'
import { useNotifications } from 'redux/state/notifications'
import { v4 as generateUUID } from 'uuid'

const Settings: React.FC = () => {
  const { addNotification } = useNotifications()

  const [clearingCache, setClearingCache] = useState<{ target: string }>({ target: '' })

  const role = useUserRole()
  const tooltip = role !== 'superuser' ? 'You are not authorized to clear the cache' : undefined

  const { chordAiClient } = useChordAi()

  const clearCache = async (target: string) => {
    try {
      setClearingCache({ target })
      const response = await chordAiClient?.clearCache(target)

      if (response) {
        addNotification({
          id: generateUUID(),
          type: response.success ? 'success' : 'warning',
          message: response.message,
        })
      } else {
        addNotification({
          id: generateUUID(),
          type: 'warning',
          message: 'Error clearing cache',
        })
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error clearing cache:', error)
    } finally {
      setClearingCache({ target: '' })
    }
  }

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 4,
          mt: 8,
          p: 4,
          border: 1,
          borderColor: 'grey.300',
          borderRadius: 2,
          bgcolor: 'grey.100',
        }}
      >
        <Typography variant="h5" fontWeight="bold">
          Settings
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Button
            purpose="primary"
            onClick={() => clearCache('kpis')}
            disabled={role !== 'superuser' || clearingCache.target === 'audiences'}
            isLoading={clearingCache.target === 'kpis'}
            tooltip={tooltip}
          >
            Clear KPIs Cache
          </Button>

          <Button
            purpose="primary"
            onClick={() => clearCache('audiences')}
            disabled={role !== 'superuser' || clearingCache.target === 'kpis'}
            isLoading={clearingCache.target === 'audiences'}
            tooltip={tooltip}
          >
            Clear Audiences Cache
          </Button>
        </Box>
      </Box>
    </Container>
  )
}

export default Settings
