import React, { useEffect, useState } from 'react'
import { useForm } from 'hooks'
import { Loading } from 'components/common'
import { useCreateTenantForm } from 'redux/hooks/useCreateTenantForm'
import { Button, Modal } from '@chordco/component-library'
import { StoreEnvironment, TenantPlanEnum } from 'types'
import { useProvisioningData } from 'redux/state/provisioning'
import createTenantSchema from './CreateTenantSchema'
import { useIsLoading } from 'redux/utils'
import { CreateTenantConfirmation } from './CreateTenantConfirmation'
import LookerConfiguration from './subforms/LookerConfiguration'
import TenantInfo from './subforms/TenantInfo'
import SolidusConfiguration from './subforms/SolidusConfiguration'
import DataSourceConfiguration from './subforms/DataSourcesConfiguration'
import DataActivationsConfiguration from './subforms/DataActivationsConfiguration'
import OmsPlanConfiguration from './subforms/OmsPlanConfiguration'
import { Container, Bottom } from './styles/common'

type Props = {
  selectedEnvironment: StoreEnvironment
  onClose: () => void
}

enum CreateTenantSections {
  TenantInfo = 'tenant_info',
  DataSources = 'data_sources',
  DataActivations = 'data_activations',
  LookerConfiguration = 'looker_configuration',
  OmsPlanConfiguration = 'oms_plan_configuration',
  SolidusConfiguration = 'solidus_configuration',
  ConfirmTenantCreation = 'confirm_tenant_creation',
}

export const CreateTenantForm: React.FC<Props> = ({ selectedEnvironment, onClose }) => {
  const [section, setSection] = useState(CreateTenantSections.TenantInfo)

  const sectionTransitions = {
    [CreateTenantSections.TenantInfo]: {
      next: selectedPlan =>
        selectedPlan === TenantPlanEnum.Autonomy
          ? CreateTenantSections.SolidusConfiguration
          : CreateTenantSections.DataSources,
    },
    [CreateTenantSections.DataSources]: {
      next: () => CreateTenantSections.DataActivations,
      prev: selectedPlan =>
        selectedPlan === TenantPlanEnum.Autonomy
          ? CreateTenantSections.SolidusConfiguration
          : CreateTenantSections.TenantInfo,
    },
    [CreateTenantSections.DataActivations]: {
      next: () => CreateTenantSections.LookerConfiguration,
      prev: () => CreateTenantSections.DataSources,
    },
    [CreateTenantSections.LookerConfiguration]: {
      next: () => CreateTenantSections.OmsPlanConfiguration,
      prev: () => CreateTenantSections.DataActivations,
    },
    [CreateTenantSections.OmsPlanConfiguration]: {
      next: () => CreateTenantSections.ConfirmTenantCreation,
      prev: () => CreateTenantSections.LookerConfiguration,
    },
    [CreateTenantSections.SolidusConfiguration]: {
      next: () => CreateTenantSections.DataSources,
      prev: () => CreateTenantSections.TenantInfo,
    },
    [CreateTenantSections.ConfirmTenantCreation]: {
      prev: () => CreateTenantSections.OmsPlanConfiguration,
    },
  }

  const navigateSection = direction => {
    const transition = sectionTransitions[section][direction]
    if (transition) {
      setSection(transition(selectedPlan))
    }
  }

  const goToNextSection = () => navigateSection('next')
  const goToPreviousSection = () => navigateSection('prev')

  const [selectedPlan, setSelectedPlan] = useState<TenantPlanEnum>(TenantPlanEnum.DataOnly)
  const [selectedOmsPlan, setSelectedOmsPlan] = useState<string>('')
  const [selectedConnectors, setSelectedConnectors] = useState<string[]>([])
  const [selectedModels, setSelectedModels] = useState<string[]>([])

  const { submit, isLoading } = useCreateTenantForm(onClose)
  const tenantSchema = createTenantSchema(selectedEnvironment)
  const { fields, onSubmit } = useForm(tenantSchema, submit)

  const {
    state: { dataConnectors },
    getDataConnectors,
  } = useProvisioningData()

  const isLoadingConnectors = useIsLoading('getDataConnectors')

  useEffect(() => {
    if (!dataConnectors.length && !isLoadingConnectors) {
      getDataConnectors()
    }
  }, [dataConnectors.length, getDataConnectors])

  const isValidTenantInfo = () => {
    return fields.name.value
  }

  const isValidSolidusConfig = () => {
    return (
      fields.solidusApiKey.value &&
      fields.solidusApiHost.value &&
      fields.solidusStoreId.value &&
      fields.solidusTenantId.value
    )
  }

  return (
    <Modal onClose={onClose} title="Create Tenant" width="640px">
      <form onSubmit={onSubmit} noValidate>
        {isLoadingConnectors && <Loading />}

        {!isLoadingConnectors && (
          <>
            <Container>
              {section === CreateTenantSections.TenantInfo && (
                <TenantInfo
                  fields={fields}
                  selectedPlan={selectedPlan}
                  setSelectedPlan={setSelectedPlan}
                  selectedEnvironment={selectedEnvironment}
                />
              )}

              {section === CreateTenantSections.SolidusConfiguration && (
                <SolidusConfiguration fields={fields} />
              )}

              {section === CreateTenantSections.DataSources && (
                <DataSourceConfiguration
                  fields={fields}
                  dataConnectors={dataConnectors}
                  selectedConnectors={selectedConnectors}
                  setSelectedConnectors={setSelectedConnectors}
                />
              )}

              {section === CreateTenantSections.DataActivations && (
                <DataActivationsConfiguration fields={fields} />
              )}

              {section === CreateTenantSections.LookerConfiguration && (
                <LookerConfiguration
                  fields={fields}
                  selectedModels={selectedModels}
                  setSelectedModels={setSelectedModels}
                />
              )}

              {section === CreateTenantSections.OmsPlanConfiguration && (
                <OmsPlanConfiguration
                  fields={fields}
                  selectedOmsPlan={selectedOmsPlan}
                  setSelectedOmsPlan={setSelectedOmsPlan}
                />
              )}

              {section === CreateTenantSections.ConfirmTenantCreation && (
                <CreateTenantConfirmation
                  fields={fields}
                  selectedConnectors={selectedConnectors}
                  selectedModels={selectedModels}
                  selectedPlan={selectedPlan}
                  dataConnectors={dataConnectors}
                  selectedEnvironment={selectedEnvironment}
                />
              )}
            </Container>

            <Bottom>
              {section === CreateTenantSections.TenantInfo && (
                <>
                  <Button onClick={onClose} purpose="secondary">
                    Cancel
                  </Button>

                  <Button
                    variant="tertiary"
                    onClick={goToNextSection}
                    disabled={!isValidTenantInfo()}
                  >
                    Next
                  </Button>
                </>
              )}

              {(section === CreateTenantSections.DataSources ||
                section === CreateTenantSections.DataActivations ||
                section === CreateTenantSections.LookerConfiguration ||
                section === CreateTenantSections.OmsPlanConfiguration) && (
                <>
                  <Button variant="tertiary" onClick={goToPreviousSection}>
                    Back
                  </Button>

                  <Button variant="tertiary" onClick={goToNextSection}>
                    Next
                  </Button>
                </>
              )}

              {section === CreateTenantSections.SolidusConfiguration && (
                <>
                  <Button variant="tertiary" onClick={goToPreviousSection}>
                    Back
                  </Button>
                  <Button
                    variant="tertiary"
                    onClick={goToNextSection}
                    disabled={!isValidSolidusConfig()}
                  >
                    Next
                  </Button>
                </>
              )}

              {section === CreateTenantSections.ConfirmTenantCreation && (
                <>
                  <Button variant="tertiary" onClick={goToPreviousSection}>
                    Back
                  </Button>

                  <Button
                    type="submit"
                    purpose="primary"
                    isLoading={isLoading}
                    disabled={isLoading}
                  >
                    Create Tenant
                  </Button>
                </>
              )}
            </Bottom>
          </>
        )}
      </form>
    </Modal>
  )
}
