import React from 'react'
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'
import { formatKpiValue } from 'components/chord-ai/utils'

interface MiniTrendChartProps {
  data: number[]
  labels: string[]
  unit?: string
}

const MiniTrendChart: React.FC<MiniTrendChartProps> = ({ data, labels, unit }) => {
  const chartData = data.map((value, index) => ({
    name: labels[index],
    value,
  }))

  // Choose chart type based on unit or KPI type
  const isPercentage = unit === '%'
  const isMonetary = unit === '$'

  return (
    <ResponsiveContainer width="100%" height={100}>
      {isPercentage ? (
        <BarChart data={chartData}>
          <XAxis dataKey="name" tick={{ fontSize: 9 }} />
          <YAxis tick={{ fontSize: 9 }} width={20} />
          <Tooltip formatter={(value: number) => formatKpiValue(value, unit || '')} />
          <Bar dataKey="value" fill="#4caf50" />
        </BarChart>
      ) : (
        <LineChart data={chartData}>
          <XAxis dataKey="name" tick={{ fontSize: 9 }} />
          <YAxis tick={{ fontSize: 9 }} width={40} />
          <Tooltip formatter={(value: number) => formatKpiValue(value, unit || '')} />
          <Line
            type="monotone"
            dataKey="value"
            stroke={isMonetary ? '#ff9800' : '#8884d8'}
            strokeWidth={2}
            dot={false}
          />
        </LineChart>
      )}
    </ResponsiveContainer>
  )
}

export default MiniTrendChart
