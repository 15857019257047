import React from 'react'
import { Switch, Route } from 'react-router-dom'
import ChordAi from 'components/chord-ai'
import KpiDetails from 'components/chord-ai/kpi/KpiDetails'
import AudienceDetails from 'components/chord-ai/audiences/AudienceDetails'
import { ChordAiProvider } from 'components/chord-ai/ChordAiContext'
import { useTenantsData } from 'redux/state/tenants'

const AiRoutes: React.FC = () => {
  const {
    state: { currentTenant, currentStore },
  } = useTenantsData()

  if (!currentTenant) throw new Error('No current tenant')
  if (!currentStore) throw new Error('currentStore is undefined')

  const clientOptions = {
    tenantId: currentTenant.id,
    storeId: currentStore.id,
  }

  return (
    <ChordAiProvider clientOptions={clientOptions}>
      <Switch>
        <Route exact path="/chord-ai" component={ChordAi} />
        <Route exact path="/chord-ai/:slug" component={ChordAi} />
        <Route path="/chord-ai/kpis/:slug" component={KpiDetails} />
        <Route path="/chord-ai/audiences/:slug" component={AudienceDetails} />
      </Switch>
    </ChordAiProvider>
  )
}

export default AiRoutes
