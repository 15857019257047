import { Avatar, Chip, H3, useTheme } from '@chordco/component-library'
import styled from 'styled-components'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import moment from 'moment'
import { useUserRoleStateDisplay } from 'hooks/useUserRoleHelper'
import { DataSettingsLogEntry } from 'types'

interface ChangeLogTableProps {
  logEntries: DataSettingsLogEntry[]
}

const ChangeLogTable: React.FC<ChangeLogTableProps> = ({ logEntries }) => {
  const theme = useTheme()

  function userRolePill(role) {
    const { text, color, variant } = useUserRoleStateDisplay(role)

    return <Chip text={text} color={color} variant={variant} />
  }

  function formatChanges(setting: string) {
    if (!setting) return ''

    const withSpaces = setting.replace(/_/g, ' ')
    return withSpaces
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }

  return (
    <ChangeLogContainer>
      <TitleContainer>
        <H3>Data Settings Change Log</H3>
      </TitleContainer>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 650, borderTop: 0, borderRight: 0, borderLeft: 0, borderBottom: 0 }}
          size="medium"
          aria-label="changelog table"
        >
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold', textAlign: 'left' }}>Changed</TableCell>
              <TableCell sx={{ fontWeight: 'bold', textAlign: 'left' }}>Date</TableCell>
              <TableCell sx={{ fontWeight: 'bold', textAlign: 'left' }}>Time</TableCell>
              <TableCell sx={{ fontWeight: 'bold', textAlign: 'left' }}>By User</TableCell>
              <TableCell sx={{ fontWeight: 'bold', textAlign: 'left' }}>User Role</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {logEntries.length === 0 && (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No changes have been made
                </TableCell>
              </TableRow>
            )}
            {logEntries.length > 0 &&
              logEntries.map(({ updatedSettings, date, time, username, userRole }, index) => (
                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell align="left">
                    {updatedSettings.map(formatChanges).join(', ')}
                  </TableCell>
                  <TableCell align="left" sx={{ color: 'grey' }}>
                    {moment(date).format('MMMM D, YYYY')}
                  </TableCell>
                  <TableCell align="left" sx={{ color: 'grey' }}>
                    {time.toUpperCase()}
                  </TableCell>
                  <TableCell align="left">
                    <StyledAvatar
                      userName={username}
                      size={24}
                      randomizeBgColor={false}
                      backgroundColor={theme.BLUE_LILAC}
                    />{' '}
                    {username}
                  </TableCell>
                  <TableCell align="left">{userRolePill(userRole)}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ChangeLogContainer>
  )
}

export default ChangeLogTable

const ChangeLogContainer = styled.div`
  top: 10px;
  position: float;
  margin-left: 10px;
  padding-bottom: 40px;
  padding-top: 20px;
`

const TitleContainer = styled.div`
  position: relative;
  top: 10px;
  padding-bottom: 20px;
`

const StyledAvatar = styled(Avatar)`
  color: ${p => p.theme.BLACK};
  size: 24px;
`
