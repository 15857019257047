import { Chip } from '@mui/material'

const GeneratedByChordAi: React.FC = () => {
  return (
    <Chip
      label="Generated by Chord AI"
      size="small"
      sx={{
        bgcolor: 'primary.light',
        fontSize: 10,
        fontWeight: 600,
        borderRadius: 20,
        ml: 1,
      }}
    />
  )
}

export default GeneratedByChordAi
