import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Card, CardContent, Box, Typography, MenuItem, Select } from '@mui/material'
import LargeTrendChart from 'components/chord-ai/charts/LargeTrendChart'
import { KpiHeader } from 'components/chord-ai/kpi/KpiHeader'
import { KeyInsights } from 'components/chord-ai/common/KeyInsights'
import { RecommendedAudiences } from 'components/chord-ai/common/RecommendedAudiences'
import { RecommendedActions } from 'components/chord-ai/common/RecommendedActions'
import ChordAiChatBot from 'components/chord-ai/chat-bot/ChatBot'
import { formatKpiValue } from 'components/chord-ai/utils'
import { useChordAi } from 'components/chord-ai/ChordAiContext'
import { Kpi } from 'components/chord-ai/types'
import { Loading } from 'components/common'

const KpiDetails: React.FC = () => {
  const { slug } = useParams<{ slug: string }>()

  const [chatOpen, setChatOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [kpi, setKpi] = useState<Kpi | null>(null)

  const { chordAiClient } = useChordAi()

  const fetchKpi = useCallback(
    async (slug: string) => {
      try {
        setIsLoading(true)
        const kpi = await chordAiClient?.getKpi(slug)
        if (kpi) setKpi(kpi)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching KPI:', error)
        throw error
      } finally {
        setIsLoading(false)
      }
    },
    [chordAiClient]
  )

  useEffect(() => {
    fetchKpi(slug)
  }, [slug])

  return (
    <>
      {isLoading && <Loading hideMask opacity={1} />}

      {!isLoading && !kpi && <Typography variant="h4">KPI not found</Typography>}

      {!isLoading && kpi && (
        <Box sx={{ width: '100%', pb: 4 }}>
          <KpiHeader kpi={kpi} />
          <Card sx={{ p: 0, mt: 2, border: 1, borderColor: 'grey.200' }}>
            <CardContent>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box>
                  <Typography variant="h5" fontWeight="bold">
                    Trend Analysis
                  </Typography>
                  <Typography variant="h4" sx={{ mt: 1 }}>
                    {formatKpiValue(kpi.value, kpi.unit)}
                  </Typography>
                </Box>
                <Select defaultValue="30d" variant="outlined" size="small">
                  <MenuItem value="7d">Last 7 days</MenuItem>
                  <MenuItem value="30d">Last 30 days</MenuItem>
                  <MenuItem value="90d">Last 90 days</MenuItem>
                </Select>
              </Box>
              <Box
                sx={{
                  backgroundColor: 'grey.100',
                  padding: 2,
                  marginTop: 2,
                  borderRadius: 1,
                }}
              >
                <LargeTrendChart
                  data={kpi.trendGraph.data}
                  labels={kpi.trendGraph.labels}
                  unit={kpi.unit}
                />
              </Box>
            </CardContent>
          </Card>

          <KeyInsights insights={kpi.keyInsights} />

          <RecommendedActions actions={kpi.recommendedActions} />

          <RecommendedAudiences audiences={kpi.recommendedAudiences} />
        </Box>
      )}

      <ChordAiChatBot
        open={chatOpen}
        onOpen={() => setChatOpen(true)}
        onClose={() => setChatOpen(false)}
      />
    </>
  )
}

export default KpiDetails
