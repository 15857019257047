import React from 'react'
import { Card, CardContent, Typography, Box } from '@mui/material'
import { Audience } from 'components/chord-ai/types'

interface AudienceCardProps {
  audience: Audience
  onClick: (audience: Audience) => void
}

const formatUserCount = (users: number) => {
  return users >= 1000 ? `${(users / 1000).toFixed(1).replace('.0', '')}k` : users.toString()
}

const AudienceCard: React.FC<AudienceCardProps> = ({ audience, onClick }) => {
  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        p: 2,
        boxShadow: 0,
        border: 1,
        borderColor: 'grey.200',
        '&:hover': {
          borderColor: 'primary.main',
          cursor: 'pointer',
        },
      }}
      onClick={() => onClick(audience)}
    >
      <CardContent sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
          <Typography variant="subtitle1" fontWeight="bold">
            {audience.name}
          </Typography>
          <Typography variant="body2" fontWeight="bold" color="text.secondary">
            {formatUserCount(audience.users)}
          </Typography>
        </Box>

        <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
          <Typography variant="body2" color="text.secondary">
            {audience.description}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  )
}

export default AudienceCard
