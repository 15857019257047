import { FormInput, FormRadioButton } from 'components/common'
import { Chip, H4 } from '@chordco/component-library'
import { TenantPlanEnum } from 'types'
import { FormField } from 'hooks'
import {
  Section,
  HeaderChip,
  FlexColumnContainer,
  FlexRowContainer,
  StyledLabel as Label,
} from 'components/provisioning/styles/common'

type Props = {
  fields: Record<string, FormField>
  selectedPlan: TenantPlanEnum
  setSelectedPlan: (plan: TenantPlanEnum) => void
  selectedEnvironment: string
}

const TenantInfo: React.FC<Props> = ({
  fields,
  selectedPlan,
  setSelectedPlan,
  selectedEnvironment,
}) => {
  return (
    <Section>
      <HeaderChip>
        <H4>Tenant Information</H4>
        <Chip
          color={selectedEnvironment !== 'staging' ? 'red' : 'amber'}
          text={selectedEnvironment}
          variant="solid"
        />
      </HeaderChip>

      <FormInput field={fields.name} customWidth="100%" />
      <FormInput field={fields.uuid} customWidth="100%" />
      <FormInput field={fields.adminEmails} customWidth="100%" />

      <FlexColumnContainer>
        <Label>Plan</Label>
        <FlexRowContainer>
          {Object.keys(TenantPlanEnum).map(plan => {
            const slug = TenantPlanEnum[plan as TenantPlanEnum]

            return (
              <FormRadioButton
                key={plan}
                field={{
                  type: 'string',
                  name: plan,
                  label: plan,
                  value: selectedPlan === slug,
                  setValue: () => {
                    setSelectedPlan(slug)
                    fields.plan.setValue(slug)
                  },
                }}
              />
            )
          })}
        </FlexRowContainer>
      </FlexColumnContainer>
    </Section>
  )
}

export default TenantInfo
