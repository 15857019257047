import { ApiClient } from 'api'
import { getName, ValOrCalc } from 'redux/utils'
import { AwaitedReturnType } from '.'

type OptionalMessages = {
  [Key in keyof ApiClient]?: ValOrCalc<
    string,
    Parameters<ApiClient[Key]>[0],
    AwaitedReturnType<ApiClient[Key]>
  > | null
}

export const successMessages: OptionalMessages = {
  updateStoreSetup: 'Store content synced successfully.',
  deleteStoreSetup: 'Store deleted.',
  createIntegration: 'Created integration.',
  updateIntegration: 'Updated integration.',
  deleteIntegration: 'Deleted integration.',
  updateUser: data => `Saved changes to ${getName(data)}.`,
  deleteUser: 'Successfully deleted user.',
  inviteUser: 'Successfully invited new user.',
  createTenant: 'Tenant created successfully',
  createDashboard: 'Dashboard created successfully',
  publishDashboard: 'Dashboard shared successfully',
  publishLook: 'Look shared successfully',
  pinDashboard: 'Report pinned successfully',
  unpinDashboard: 'Report unpinned successfully',
  pinLook: 'Report pinned successfully',
  unpinLook: 'Report unpinned successfully',
  copyDashboard: 'Dashboard copied successfully',
  importReport: 'Report imported successfully',
  copyLook: 'Look copied successfully',
  updateDataSettings:
    "Changes to data settings will not take effect until your store's next scheduled data refresh.",
  publishAudience: 'Audience template published successfully',
}

export const failureMessages: OptionalMessages = {
  updateStoreSetup: 'An error occurred while trying to update the store.',
  deleteStoreSetup: 'An error occurred while trying to delete the store.',
  createIntegration: 'An error occurred while trying to create the integration',
  updateIntegration: 'An error occurred while trying to update the integration.',
  deleteIntegration: 'An error occurred while trying to delete the integration.',
  runLook: 'An error occurred while fetching some metric tile data.',
  getTiles: (_, __, err) =>
    err?.response?.data?.details?.includes('login failed')
      ? null // return null to do not show error notification
      : err?.response?.data?.details,
  updateUser: data => `An error occurred attempting to update user ${getName(data)}`,
  deleteUser: 'An error occurred while trying to delete user.',
  inviteUser: 'An error occurred while trying to invite new user.',
  getStripeWebhooks: null,
  createTenant: 'An error occurred while trying to create the tenant',
  createDashboard: 'An error occurred while trying to create the dashboard',
  publishDashboard: 'An error occurred while trying to publish the dashboard',
  publishLook: 'An error occurred while trying to publish the look',
  unpinDashboard: 'An error occurred while trying to unpin your report',
  unpinLook: 'An error occurred while trying to unpin your report',
  copyDashboard: 'An error occurred while trying to copy the dashboard',
  importReport: 'An error occurred while trying to import the report',
  copyLook: 'An error occurred while trying to copy the look',
  updateDataSettings: 'An error occurred while trying to save the settings',
  publishAudience: 'An error occurred while trying to publish the audience template',
}
