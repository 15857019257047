import styled from 'styled-components'
import { FormCheckbox } from 'components/common'
import { Label } from '@chordco/component-library'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 24px;
  margin-bottom: 70px;
`

export const HeaderChip = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  gap: 8px;
  width: 100%;
`

export const FlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 8px;
`

export const StyledLabel = styled(Label)`
  white-space: pre-wrap;
  font-family: sans-serif;
`

export const FlexRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
`

export const Bottom = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${p => p.theme.ComponentWorkspaceBgPage};
  border-top: 1px solid ${p => p.theme.BorderHairline};
  padding: 16px;
  z-index: 1;
  height: 70px;
  border-radius: 0 0 ${p => p.theme.CardLargeRadius} ${p => p.theme.CardLargeRadius};
  display: flex;
  gap: 8px;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`

export const CheckboxList = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 10px;
`

export const StyledCheckbox = styled(FormCheckbox)`
  width: 100%;
  flex-basis: 50%;
  margin-top: 0;
`
