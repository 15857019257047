import React from 'react'
import { BarChart } from '@mui/icons-material'
import { Box, Card, CardContent, Typography } from '@mui/material'
import { KeyInsight } from 'components/chord-ai/types'
import { Button } from '@chordco/component-library'
import GeneratedByChordAi from 'components/chord-ai/common/GeneratedByChordAi'

interface KeyInsightsProps {
  insights: KeyInsight[]
}

export const KeyInsights: React.FC<KeyInsightsProps> = ({ insights }) => {
  return (
    <Card sx={{ p: 0, mt: 2, border: 1, borderColor: 'grey.200' }}>
      <CardContent>
        <Box display="flex" justifyContent="flex-start" alignItems="center" gap={1}>
          <Typography variant="h6" fontWeight="bold">
            Key Insights
          </Typography>
          <GeneratedByChordAi />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 1 }}>
          {insights.map((insight, index) => (
            <Box
              key={index}
              sx={{
                p: 2,
                border: 1,
                borderColor: 'grey.200',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: 1,
                borderRadius: 2,
              }}
            >
              <Typography key={index} variant="body1">
                {insight.description}
              </Typography>
              <Button purpose="tertiary" icon={BarChart} size="small" centreContent>
                Show me the data
              </Button>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  )
}
