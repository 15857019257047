import React from 'react'
import { LightbulbOutlined } from '@mui/icons-material'
import { Card, CardContent, Typography, Box } from '@mui/material'
import { RecommendedAction } from 'components/chord-ai/types'
import GeneratedByChordAi from 'components/chord-ai/common/GeneratedByChordAi'

interface RecommendedActionsProps {
  actions: RecommendedAction[]
}

export const RecommendedActions: React.FC<RecommendedActionsProps> = ({ actions }) => {
  return (
    <Card sx={{ p: 0, mt: 2, border: 1, borderColor: 'grey.200' }}>
      <CardContent>
        <Box display="flex" justifyContent="flex-start" alignItems="center" gap={1}>
          <Typography variant="h6" fontWeight="bold">
            Improvement Hypotheses
          </Typography>
          <GeneratedByChordAi />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 1 }}>
          {actions.map((action, index) => (
            <Typography
              key={index}
              variant="body1"
              sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
            >
              <LightbulbOutlined fontSize="small" />
              {action.description}
            </Typography>
          ))}
        </Box>
      </CardContent>
    </Card>
  )
}
