import { useState } from 'react'
import { OptionType, Switcher } from 'components/navigation/Switcher'
import { Tenant as TenantType } from 'types'
import styled from 'styled-components'
import { Button } from '@chordco/component-library'
import { Plus } from '@chordco/component-library/components/icons/standardIcons/Plus'
import useUserRole from 'redux/hooks/useUserRole'
import { CreateTenantForm } from 'components/provisioning/CreateTenantForm'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store'

interface Props {
  tenants: TenantType[]
  currentTenant: TenantType | undefined
  selectTenant: (tenantId: number) => void
}

export const Tenant: React.FC<Props> = ({ tenants, currentTenant, selectTenant }) => {
  const [showCreateTenant, setShowCreateTenant] = useState(false)

  const selectedEnvironment = useSelector(
    (state: RootState) => state.environments.selectedEnvironment
  )

  const selectOption = (option: OptionType) => selectTenant(option.id)

  const role = useUserRole()

  const toggleCreateTenantForm = () => setShowCreateTenant(!showCreateTenant)

  return (
    <>
      {role === 'superuser' && (
        <ButtonContainer>
          <Button icon={Plus} variant="tertiary" onClick={toggleCreateTenantForm}>
            Add New Tenant
          </Button>
        </ButtonContainer>
      )}

      <Switcher
        options={tenants}
        selectOption={selectOption}
        selectedOption={currentTenant}
        type="tenant"
        enableSearch
        sortAlphabetically
      />

      {showCreateTenant && selectedEnvironment && role === 'superuser' && (
        <CreateTenantForm
          onClose={toggleCreateTenantForm}
          selectedEnvironment={selectedEnvironment}
        />
      )}
    </>
  )
}

const ButtonContainer = styled.div`
  padding-left: 16px;
  padding-bottom: 16px;
  padding-right: 16px;

  button {
    width: 100%;
  }
`
