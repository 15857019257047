import { TextField } from '@mui/material'
import { CredentialsConfig } from 'components/chord-cdp/configs'
import GenericCredentialsForm from '../GenericCredentialsForm'

export const facebookPixelCredentialsConfig: CredentialsConfig = {
  pixelId: {
    description:
      "Your Facebook Pixel ID from the snippet created on the <a href='https://www.facebook.com/ads/manager/pixel/facebook_pixel/' target='_blank' rel='noreferrer noopener'>Facebook Pixel creation page</a>.",
    renderComponent: TextField,
    componentProps: {
      label: 'Facebook Pixel ID',
      type: 'text',
      required: true,
    },
  },
}

export const FacebookPixelCredentialsForm = props => (
  <GenericCredentialsForm {...props} config={facebookPixelCredentialsConfig} />
)
