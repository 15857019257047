import React from 'react'
import { AlertModal } from '@chordco/component-library'
import { Warning } from '@mui/icons-material'

type NoChangesProps = {
  onClose: () => void
}

export const NoChanges: React.FC<NoChangesProps> = ({ onClose }) => {
  return (
    <AlertModal
      onClose={onClose}
      icon={Warning}
      content="No changes were made to save."
      subContent="Please make changes to the data settings before saving."
      centered
      buttons={[
        {
          id: 1,
          text: 'Ok',
          onClick: onClose,
          purpose: 'tertiary',
        }
      ]}
    />
  )
}
