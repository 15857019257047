import { Box, Typography, Paper } from '@mui/material'
import SmartToyIcon from '@mui/icons-material/SmartToy'
import { ChatBubbleOutline } from '@mui/icons-material'
import { Button } from '@chordco/component-library'

type Props = {
  message: string
  onAskQuestion: () => void
}

const ChordAiMessage: React.FC<Props> = ({ message, onAskQuestion }) => {
  return (
    <Box sx={{ mx: 'auto', mt: 2 }}>
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          p: 2,
          borderRadius: 2,
          position: 'relative',
          backgroundColor: '#FFFFFF',
          border: 1,
          borderColor: 'primary.main',
        }}
        elevation={0}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <SmartToyIcon sx={{ color: 'text.secondary' }} />
          <Typography variant="body1" color="text.secondary">
            <strong>Chord AI:</strong> {message}
          </Typography>
        </Box>

        <Button
          purpose="tertiary"
          size="small"
          icon={ChatBubbleOutline}
          onClick={onAskQuestion}
          centreContent={true}
          style={{ alignSelf: 'flex-end' }}
        >
          Ask a question
        </Button>
      </Paper>
    </Box>
  )
}

export default ChordAiMessage
